import React from "react";
import { useParams } from "react-router-dom";
import { config } from "../constant";
import { useFetchQuery } from "../hooks/useFetchQuery";
import Loader from "../components/Loader";
const URL = config.url;

function BlogView() {

  const { id, slug } = useParams();
  const url = `${URL}/api/getdata/getpost/${slug}`;
  const [data, loading, error] = useFetchQuery(url);
  const article = data;
  function createMarkup(data) {
    return {__html: data};
  }

  return (
    <>
      {loading ? (
        <>
          <Loader/>
        </>
      ) : (
        <>
          <div className="max-w-[1100px] mx-auto">
          <div
              style={
                article.image
                  ? { backgroundImage: `url(${article.image})` }
                  : {
                      backgroundImage: `url('https://source.unsplash.com/random/500%C3%97300/?hospital')`,
                    }
              }
              className={`bg-cover bg-center bg-no-repeat w-full h-[10rem] md:h-[15rem]`}
            >
              <div className="w-full  h-full bg-white/60 flex justify-center items-center">
                <p className="text-2xl md:text-4xl font-bold text-center capitalize">
                  {article.title}
                </p>
              </div>
            </div>
            <div>
              <div className="p-2 md:p-4 lg:p-10 py-8 ">
                {/**remove img tag to remove top image from blogpage */}
                {/** 
                <div style={{backgroundImage:`url(${article.image})`}} className={article.image?`bg-cover bg-center bg-no-repeat w-full sm:w-2/3 mx-auto p-2 md:w-3/5 aspect-video overflow-hidden`:'hidden'}></div>
              */}
              <div dangerouslySetInnerHTML={createMarkup(article.article)} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BlogView;
