import React from 'react';
import ShowDetails from "./ShowDetails";
import ButtonModal from "./ButtonModal";
import { useFetchQuery } from "../../hooks/useFetchQuery";
import { config } from "../../constant";
import useAuth from '../../hooks/useAuth';
const URL = config.url;

function Appointments() {
  //const token = localStorage.getItem('token');
  const {token} = useAuth();
  const url = `${URL}/api/getdata/getappointments/?authTocken=${encodeURIComponent(token)}`
  const [appointments, loading, error] = useFetchQuery(url)
  function formatDate(date) {
    var newdate = new Date(date);
    var day = newdate.getDate();
    var month = newdate.getMonth() + 1;
    var year = newdate.getFullYear()
    var newdate = day + "-" + month + "-" + year;
    return newdate
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);

  function openModal(e) {
    const dataid = e.target.getAttribute('dataid');
    setModalData(appointments.find((appointments) => appointments._id === dataid))

    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      <div className="p-2 md:p-4">
        <ShowDetails isOpen={modalIsOpen} closeBtn={closeModal} data={modalData} />
        <div className="py-2 flex justify-between border-b-2 border-custom-green">
          <p className="text-xl font-bold text-custom-green">
            Appointments
          </p>
        </div>
        <div className="p-2">
          <div class="relative overflow-x-auto">
            <table class="w-full text-sm text-left rtl:text-right">
              <thead class="text-lg font-sans uppercase border-b-2 border-b-green">
                <tr>
                  <th class="px-6 py-3">
                    Name
                  </th>
                  <th class="px-6 py-3">
                    Email
                  </th>
                  <th class="px-6 py-3 text-center ">
                    Date
                  </th>
                  <th class="px-6 py-3 text-center ">
                  </th>
                </tr>
              </thead>
              <tbody className="text-base font-medium">
                {appointments.map((appointment) => (
                  <tr class="bg-white border-b dark:bg-green-400 dark:border-custom-green hover:bg-green-200">
                    <td class="px-6 py-2">{appointment.name}</td>
                    <td class="px-6 py-2">{appointment.email}</td>
                    <td class="px-6 py-2 text-center">{formatDate(appointment.appointmentDate)}</td>
                    <td className="px-6 py-2 text-center">
                      <ButtonModal className="text-custom-green hover:text-custom-green/55" data={appointment} dataType="appointment">
                        view
                      </ButtonModal>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appointments