import React, { useState } from "react";
import { config } from "../constant";
const URL = config.url;

function ContactForm() {
  let initialQuery = {
    name: "",
    email: "",
    phone: "",
    message: ""
  }
  const [query, setQuery] = useState(initialQuery)

  const onchange = (e)=>{
    setQuery({...query, [e.target.name]:e.target.value})
    //console.log(query)
  }
  const handleSubmit = async (e)=>{
    e.preventDefault();
    const {name, email, phone, message} = query;
    const url = `${URL}/api/form/query`
    const responce = await fetch(url,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name, email, phone, message})
    });
    const json = await responce.json()
    if (json.sucess) {
      alert(json.sucess)
    } else{
      alert("enter a valid value");
    }
  }
  return (
    <div className="ContactUsForm w-full p-2 lg:p-8">
      <form onSubmit={handleSubmit} class="w-full">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
              for="query-name"
            >
              Name <span className="text-red-800">*</span>
            </label>
            <input
              class="appearance-none block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="query-name"
              name="name"
              type="text"
              placeholder="Doe" required
              onChange={onchange}
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
              for="query-phone"
            >
              Phone No. <span className="text-red-800">*</span>
            </label>
            <input
              class="appearance-none block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="query-phone"
              type="phone"
              name="phone"
              placeholder="123456789"
              required
              onChange={onchange}
            />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
              for="query-email"
            >
              Email. <span className="text-red-800">*</span>
            </label>
            <input
              class="appearance-none block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="query-email"
              type="email"
              name="email"
              placeholder="abc123@abc.com" required
              onChange={onchange}
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
              for="query-description"
            >
              Message Here <span className="text-red-800">*</span>
            </label>
            <textarea rows="4" cols="50"
              class="appearance-none block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="query-description"
              name="message"
              placeholder="write your message here" required
              onChange={onchange}
            ></textarea>
          </div>
        </div>
        <div class="w-ful max-w-lg pt-8">
          <button class="bg-custom-green w-full hover:bg-custom-green/80 text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
