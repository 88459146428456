import React from "react";
import img2 from "../assets/images/img2.jpg";
import contactImg from "../assets/images/contact-img.png";
import ContactForm from "./ContactForm";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 relative overflow-hidden py-10">
        <img
          src={contactImg}
          className="absolute blur-sm top-0 left-0 h-full md:w-full md:h-auto -z-10"
          alt=""
        />
        <div className="w-full flex flex-col justify-center p-4 sm:pl-10">
          <p className="text-c1 pb-4 text-4xl sm:text-6xl font-semibold">
            Have Questions ?
          </p>
          <p className="text-c1 pb-4 text-3xl sm:text-4xl font-semibold">
            Drop a message
          </p>
          <p className="text-c2 pb-4 text-base md:text-lg">
            Have a question for us or feedback? Please fill out the form to
            reach us.
          </p>
        </div>
        <div className="md:w-4/5 mx-auto shadow-xl p-4">
          <div className="p-4 bg-white opacity-80">
            <ContactForm />
          </div>
        </div>
      </div>
      <footer className="border-t-2 border-t-custom-green ">
        <div className="hidden  grid-cols-1 lg:grid-cols-2 p-2 md:p-8 gap-4">
          <div className="p-2">
            <div className="pb-2">
              <p className="text-4xl py-2 border-b-2 border-b-gray-600 text-gray-600">
                Address
              </p>
            </div>
            <div
              id="locationOnMap"
              className="w-full py-4 aspect-square md:aspect-video"
            >
              <div className=" py-4 h-full flex justify-center items-center">
                <iframe
                  className="w-full h-full"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13775.833317433404!2d78.0148196!3d30.3237031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092a1ec7f2bfbf%3A0x6572c4705935d009!2sAyurmax%20Hospital!5e0!3m2!1sen!2sin!4v1704200841822!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="pl-2 pt-2">
              <div className="flex flex-row gap-2">
                <div>
                  <p className="text-3xl text-custom-green ">
                    <i class="bi bi-geo-alt-fill"></i>
                  </p>
                </div>
                <div>
                  <p className="text-3xl text-custom-green  pb-2">
                    Ayurmax Hospital
                  </p>
                  <p id="addline-1" className="py-1 text-lg">
                    82 Vivek Vihar Pocket 3,
                  </p>
                  <p id="addline-2" className="py-1 text-lg">
                    Balliwala Chowk, Vijay Park Extension,
                  </p>
                  <p id="addline-2" className="py-1 text-lg">
                    Dehradun, Uttarakhand 248001
                  </p>
                  <p id="addline-3" className="py-1 text-lg">
                    +91 123456780
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2 py-2">
                <div>
                  <p className="text-3xl text-custom-green ">
                    <i class="bi bi-alarm"></i>
                  </p>
                </div>
                <div>
                  <p className="text-3xl text-custom-green  pb-2"> Hours</p>
                  <p id="daytimeline-1" className="py-1 text-lg">
                    Monday: 10am-8pm
                  </p>
                  <p id="daytimeline-2" className="py-1 text-lg">
                    Tuesday: 10am-8pm
                  </p>
                  <p id="daytimeline-3" className="py-1 text-lg">
                    Wedenesday: 10am-8pm
                  </p>
                  <p id="daytimeline-4" className="py-1 text-lg">
                    Thursday: 10am-8pm
                  </p>
                  <p id="daytimeline-5" className="py-1 text-lg">
                    Friday: 10am-8pm
                  </p>
                  <p id="daytimeline-6" className="py-1 text-lg">
                    Suterday: 10am-8pm
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="flex flex-col items-center">
              <div className=" w-full flex flex-row py-4 justify-center items-center">
                <Link to="/">
                  <img className="h-20 w-auto" src={logo} alt="Workflow" />
                </Link>
              </div>
              <div className=" w-full py-8 justify-center items-center">
                <p className="w-full text-3xl text-center">Parterns detail</p>
                <div className="flex gap-8 flex-wrap justify-evenly py-8">
                  <div className="min-w-[10rem] overflow-hidden w-1/3 sm:w-1/4 aspect-square bg-white">
                    <img
                      className="w-full origin-center"
                      src="https://karenthrelkelnd.com/wp-content/uploads/2023/04/2023.png"
                      alt=""
                    />{" "}
                  </div>
                  <div className="min-w-[10rem] overflow-hidden w-1/3 sm:w-1/4 aspect-square bg-white">
                    <img
                      className="w-full origin-center"
                      src="https://karenthrelkelnd.com/wp-content/uploads/2019/06/Screen-Shot-2019-06-03-at-4.19.05-PM.png"
                      alt=""
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="p-4">
                <p className="px-2 md:px-4 lg:px-8 text-lg text-green-500 text-center">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Veritatis sed rem facere quaerat neque earum dolores possimus
                  mollitia, molestias voluptatem?
                </p>
              </div>
              <div className="p-8">
                <div className="grid grid-flow-col gap-4">
                  <a
                    className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                    href="https://www.facebook.com/arvind.chaudhry.714"
                    target="_blank"
                  >
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a
                    className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                    href="https://www.youtube.com/@ayurmaxhospital9179"
                    target="_blank"
                  >
                    <i class="bi bi-youtube"></i>
                  </a>
                  <a
                    className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                    href="https://www.instagram.com/drarvindchaudhary/"
                    target="_blank"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a
                    className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                    href=""
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="mx-auto max-w-7xl px-2 sm:px-4">
            <div class="mx-auto max-w-7xl py-12 md:py-24">
              <div className="hidden w-full">
                <div className="px-2 flex flex-row items-center justify-between">
                  <div className="flex flex-row py-4 justify-center items-center">
                    <Link to="/">
                      <img
                        className="h-[50px] w-auto"
                        src={logo}
                        alt="Workflow"
                      />
                    </Link>
                  </div>
                  <div className="">
                    <div className="grid grid-flow-col gap-4">
                      <a
                        className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                        href="https://www.facebook.com/arvind.chaudhry.714"
                        target="_blank"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a
                        className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                        href="https://www.youtube.com/@ayurmaxhospital9179"
                        target="_blank"
                      >
                        <i class="bi bi-youtube"></i>
                      </a>
                      <a
                        className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                        href="https://www.instagram.com/drarvindchaudhary/"
                        target="_blank"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a
                        className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                        href=""
                      >
                        <i className="bi bi-twitter"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid items-center justify-items-center gap-x-4 gap-y-4 md:gap-y-10 lg:grid-cols-2">
                <div class="w-full">
                  <div className="px-2 py-4 flex flex-col md:flex-row items-center justify-between">
                    <div className="flex flex-row py-4 justify-center items-center">
                      <Link to="/">
                        <img
                          className="h-[60px] w-auto"
                          src={logo}
                          alt="Workflow"
                        />
                      </Link>
                    </div>
                    <div className="">
                      <div className="grid grid-flow-col gap-4">
                        <a
                          className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                          href="https://www.facebook.com/arvind.chaudhry.714"
                          target="_blank"
                        >
                          <i class="bi bi-facebook"></i>
                        </a>
                        <a
                          className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                          href="https://www.youtube.com/@ayurmaxhospital9179"
                          target="_blank"
                        >
                          <i class="bi bi-youtube"></i>
                        </a>
                        <a
                          className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                          href="https://www.instagram.com/drarvindchaudhary/"
                          target="_blank"
                        >
                          <i className="bi bi-instagram"></i>
                        </a>
                        <a
                          className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                          href=""
                        >
                          <i className="bi bi-twitter"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="px-2">
                    <div className="pb-2">
                      <p className="text-2xl md:text-4xl py-2 border-b-2 border-custom-green text-custom-green">
                        Address
                      </p>
                    </div>
                    <div className="pl-2 pt-2">
                      <div className="flex flex-row gap-2">
                        <div>
                          <p className=" text-custom-green text-xl md:text-2xl font-semibold ">
                            <i class="bi bi-geo-alt-fill"></i>
                          </p>
                        </div>
                        <div className="">
                          <p className=" text-custom-green text-xl md:text-2xl font-semibold pb-2">
                            Ayurmax Hospital
                          </p>
                          <p id="addline-1" className="pt-1 text-base sm:text-lg">
                            82 Vivek Vihar Pocket 3,
                          </p>
                          <p id="addline-2" className="pt-1 text-base sm:text-lg">
                            Balliwala Chowk, Vijay Park Extension,
                          </p>
                          <p id="addline-2" className="pt-1 text-base sm:text-lg">
                            Dehradun, Uttarakhand 248001
                          </p>
                          <p id="addline-3" className="pt-1 text-base sm:text-lg">
                            +91 8171200004
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row gap-2 py-2">
                        <div>
                          <p className="text-xl md:text-2xl font-semibold text-custom-green ">
                            <i class="bi bi-alarm"></i>
                          </p>
                        </div>
                        <div>
                          <p className="text-xl md:text-2xl font-semibold text-custom-green  pb-2">
                            {" "}
                            Hours
                          </p>
                          <p id="daytimeline-1" className="pt-1 text-base md:text-lg">
                            Monday: 10am-8pm
                          </p>
                          <p id="daytimeline-2" className="pt-1 text-base md:text-lg">
                            Tuesday: 10am-8pm
                          </p>
                          <p id="daytimeline-3" className="pt-1 text-base md:text-lg">
                            Wedenesday: 10am-8pm
                          </p>
                          <p id="daytimeline-4" className="pt-1 text-base md:text-lg">
                            Thursday: 10am-8pm
                          </p>
                          <p id="daytimeline-5" className="pt-1 text-base md:text-lg">
                            Friday: 10am-8pm
                          </p>
                          <p id="daytimeline-6" className="pt-1 text-base md:text-lg">
                            Suterday: 10am-8pm
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="locationOnMap"
                  className="w-full md:aspect-square aspect-video"
                >
                  <div className="h-full flex justify-center items-center">
                    <iframe
                      className="w-full h-full"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13775.833317433404!2d78.0148196!3d30.3237031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092a1ec7f2bfbf%3A0x6572c4705935d009!2sAyurmax%20Hospital!5e0!3m2!1sen!2sin!4v1704200841822!5m2!1sen!2sin"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-6" />
          <div className="bg-custom-green">
            {/**Logo with socil icon */}
            <div className="pt-8 hidden">
              <div className="px-2 flex flex-col items-center justify-between">
                <div className="flex flex-row py-4 justify-center items-center">
                  <Link to="/">
                    <img
                      className="h-[60px] w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                  </Link>
                </div>
                <div className="">
                  <div className="grid grid-flow-col gap-4">
                    <a
                      className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                      href="https://www.facebook.com/arvind.chaudhry.714"
                      target="_blank"
                    >
                      <i class="bi bi-facebook"></i>
                    </a>
                    <a
                      className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                      href="https://www.youtube.com/@ayurmaxhospital9179"
                      target="_blank"
                    >
                      <i class="bi bi-youtube"></i>
                    </a>
                    <a
                      className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                      href="https://www.instagram.com/drarvindchaudhary/"
                      target="_blank"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a
                      className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-custom-green  bg-custom-green  hover:bg-white hover:text-custom-green  px-2 py-1 text-xl font-bold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                      href=""
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-10">
              <ul class="mx-auto flex flex-col justify-center text-center md:flex-row text-lg text-white">
                <li class="p-3 mx-2 py-1 hover:scale-105 ease-linear duration-300">
                  <a href="/">Home</a>
                </li>
                <li class="p-3 mx-2 py-1 hover:scale-105 ease-linear duration-300">
                  <a href="/cancercure">Cancer Cure</a>
                </li>
                <li class="p-3 mx-2 py-1 hover:scale-105 ease-linear duration-300">
                  <a href="/approach">Holistic Approach</a>
                </li>
                <li class="p-3 mx-2 py-1 hover:scale-105 ease-linear duration-300">
                  <a href="/story">My Story</a>
                </li>
                <li class="p-3 mx-2 py-1 hover:scale-105 ease-linear duration-300">
                  <a href="/blogs">Blogs</a>
                </li>
                <li class="p-3 mx-2 py-1 hover:scale-105 ease-linear duration-300">
                  <a href="/faq">FAQ</a>
                </li>
                <li class="p-3 mx-2 py-1 hover:scale-105 ease-linear duration-300">
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-custom-green flex flex-col justify-center items-center py-8">
          <p className="text-gray-300 text-base text-center cursor-pointer">
            <a href="#">
              {" "}
              © 2023 Developers and orgnization | All rights reserved | Sitemap
              | Privacy Policy
            </a>
          </p>
          <p className="text-lg text-gray-300 cursor-pointer">
            <a href="#">Developers log & details</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
