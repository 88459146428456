import React from "react";
import aromatherapyImg from "../assets/images/Aromatherapy.jpg"
import mindImg from "../assets/images/Mind.jpg"
import rasayanaTherapyImg from "../assets/images/RasayanaTherapy.jpg"
import lifestyleImg from "../assets/images/lifestyle.jpg"
import DietaryChanges from "../assets/images/DietaryChanges.jpg"
import PanchakarmaImg from "../assets/images/Panchakarma.jpg"
function HolisticApproach() {
  return (
    <div>
      <div className="">
        <section class="relative bg-[url(https://karenthrelkelnd.com/wp-content/uploads/2019/05/naturopathic-medicine-week.jpg)] bg-cover bg-center bg-no-repeat">
          <div className="backdrop-blur-sm h-[10rem] md:h-[15rem] flex items-center justify-center">
            <div className="p-2 py-8 text-black">
              <p className="heading-c1 text-4xl lg:text-6xl font-semibold text-center text-black">
                Holistic Approach
              </p>
            </div>
          </div>
        </section>
      </div>
      <div id="containerBlogs" class="MainContainer px-2 sm:p-1">
      <div class="blog blog1">
          <div class="sideImg">
            <img
              src="https://res.cloudinary.com/dpzhezt6x/image/upload/v1705512246/Herbal_Medicine_Imagens_e_fotografias_de_stock_bsjw0c.jpg"
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
              Ayurvedic Remedies
              </h1>
              <p className="text-base md:text-lg">
                Ayurvedic herbs are believed to have various healing properties.
                While there is limited scientific evidence, some herbs like
                ashwagandha, turmeric, and tulsi (holy basil) are thought to
                have potential anti-cancer and immune-boosting properties.</p>
              <p className="text-base md:text-lg mt-2">
                However, it's crucial to consult with a healthcare professional
                before incorporating any herbal supplements into a cancer
                treatment plan.
              </p>
            </div>
          </div>
        </div>
        <div class="blog blog1">
          <div className="sideImg bg-white">
            <img
              src={PanchakarmaImg}
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
                Detoxification (Panchakarma)
              </h1>
              <p className="text-base md:text-lg">
                Panchakarma is an Ayurvedic detoxification therapy that aims to
                eliminate toxins from the body. It includes procedures like
                massage, herbal steam, and cleansing diets. While there is
                limited scientific evidence supporting its efficacy in cancer
                treatment, some individuals find it beneficial for overall
                well-being.
              </p>
              <p className="text-base md:text-lg mt-2">
                Panchakarma eliminates toxins accumulated in the body as a result of poor diet, stress, environmental pollutants, and other factors, allowing the body's natural healing mechanisms to function optimally.
              </p>
            </div>
          </div>
        </div>
        <div class="blog blog1">
          <div class="sideImg">
            <img
              src={DietaryChanges}
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
                Dietary Changes
              </h1>
              <p className="text-base md:text-lg">
                Ayurveda emphasizes a balanced and individualized diet based on
                one's dosha (constitution). A diet rich in fruits, vegetables,
                whole grains, and plant-based proteins may be recommended.</p>
              <p className="text-base md:text-lg mt-2">
                Specific foods and herbs with potential anti-inflammatory and
                antioxidant properties, such as turmeric, ginger, and green tea,
                may be included.
              </p>
              <p className="text-base md:text-lg mt-2">Healthy Eating Habits to Lower Cancer Risk: Emphasizing Plant-Based Foods, Lean Proteins, and Hydration</p>
            </div>
          </div>
        </div>
        <div class="blog blog1">
          <div class="sideImg">
            <img
              src={lifestyleImg}
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
                Lifestyle Modifications:
              </h1>
              <p className="text-base md:text-lg">
                Ayurveda emphasizes the importance of aligning daily routines with natural rhythms to promote balance. Lifestyle modifications may include adopting a regular sleep pattern, engaging in moderate exercise, and practicing mindful eating.</p>
              <p className="text-base md:text-lg mt-2"> Creating a supportive and nurturing environment at home is also considered essential for overall well-being.
              </p>
            </div>
          </div>
        </div>
        <div class="blog blog1">
          <div class="sideImg">
            <img
              src="https://res.cloudinary.com/dpzhezt6x/image/upload/v1705512083/Here_s_What_I_Learned_After_Swapping_My_Gym_Time_For_Meditation_Classes_n9ysna.jpg"
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
                Yoga and Meditation
              </h1>
              <p className="text-base md:text-lg">
                Yoga and meditation play pivotal roles in holistic cancer care, offering physical and psychological benefits. Yoga enhances flexibility, strength, and balance, while promoting relaxation and overall well-being through asanas, pranayama, and meditation.
              </p>
              <p className="text-base md:text-lg mt-2">Meditation, including mindfulness and guided imagery, aids in coping with emotional challenges, reducing anxiety and depression, and improving mood. These practices complement conventional treatments by enhancing sleep, boosting immunity, and reducing inflammation.</p>
            </div>
          </div>
        </div>
        
        
        <div class="blog blog1">
        <div class="sideImg">
            <img
              src={aromatherapyImg}
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
                Aromatherapy and Ayurvedic Oils:
              </h1>
              <p className="text-base md:text-lg">
                Aromatherapy, using essential oils derived from plants, is often integrated into Ayurvedic practices. Certain essential oils, such as frankincense and sandalwood, are believed to have therapeutic properties that can positively impact mental and emotional well-being.</p>
              <p className="text-base md:text-lg mt-2"> Ayurvedic oils, such as Mahanarayan oil or Brahmi oil, may be used in therapeutic massages to promote relaxation and balance.
              </p>
            </div>
          </div>
        </div>
        <div class="blog blog1">
          <div class="sideImg">
            <img
              src={mindImg}
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
                Mind-Body Connection:
              </h1>
              <p className="text-base md:text-lg">
              Navigating the mind-body link is particularly important in cancer care. Recognising the link between mental and physical health, cancer patients can use mindfulness activities like meditation, visualisation, and deep breathing to develop inner strength and resilience.
              </p>
              <p className="text-base md:text-lg mt-2">These techniques not only help to manage stress, anxiety, and depression, but they also aid in the whole healing process by encouraging relaxation and boosting the body's natural ability to battle sickness.</p>
            </div>
          </div>
        </div>
        <div class="blog blog1">
        <div className="sideImg bg-white">
            <img
              src={rasayanaTherapyImg}
              alt=""
            />
          </div>
          <div class="sideCont">
            <div>
              <h1 className="text-c2 text-xl sm:text-3xl font-semibold pb-4">
                Rasayana Therapy:
              </h1>
              <p className="text-base md:text-lg">
                Rasayana, a branch of Ayurveda, focuses on rejuvenation and restoration of vitality. Rasayana therapies include the use of specific herbs, diet, and lifestyle practices to enhance immunity and promote longevity.
                </p>
                <p className="text-base md:text-lg mt-2"> While Rasayana is not a direct cancer treatment, it is considered beneficial in supporting overall health and vitality during and after conventional cancer therapies.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default HolisticApproach;
