import React from "react";

function Contact() {
  return (
    <div>
      <section class="relative bg-[url(https://images.unsplash.com/photo-1512586175314-701bd44f8440?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8Y2l0eXx8fHx8fDE3MDQ5NTQ5MjA&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080)] bg-cover bg-center bg-no-repeat">
          <div className="backdrop-blur-sm h-[10rem] md:h-[15rem] flex items-center justify-center">
            <div className="p-2 py-8 text-black">
              <p className="heading-c1 text-4xl lg:text-6xl font-semibold text-center text-black">
                Contact Us
              </p>
            </div>
          </div>
        </section>
      <div className="grid grid-col-1 lg:grid-cols-2 p-5 lg:p-10">
        <div className="lg:p-10 relative py-4">
          <p className="heading-c1 text-3xl md:text-4xl font-semibold text-gray-800 relative py-4">
            Get In Touch
            <span className="absolute bottom-0 left-0 w-1/3 border-b-2 border-b-gray-800"></span>
          </p>
          <div className="pt-4">
          <p className="text-xl py-2">To Reach Ayurmax Hospital Or To Schedule An Appointment </p>

          <p className="text-2xl"><i class="bi bi-phone-fill text-custom-green"></i>(+91) 8171200004</p>

          <p className="text-xl py-2">Sunday – Saturday: 24 Hours </p>

          <p className="text-xl py-2">Restorative Health: </p>

          <p className="text-xl py-2">
          82 Vivek Vihar Pocket 3, Balliwala Chowk, Vijay Park Extension, Dehradun, Uttarakhand 248001
          </p>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="w-4/5">
          <img src="https://res.cloudinary.com/dpzhezt6x/image/upload/v1705514333/2018-02-26_rrd9em.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
