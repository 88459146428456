import React, { useState } from 'react'

function Quries() {
    const [current, setCurrent] = useState(null)
    const faqs = [
        {
            question: "Why Ayurmax?",
            answer: ["Ayurmax is a multispecialty healthcare institution of synchronized Ayurvedic medical procedures and modern technology.  Ayurmax is the best ayurvedic treatments hospital in Dehradun which provide top quality healthcare to its patients and work on the root causes of the diseases.The healthcare professionals at Ayurmax are certified and experienced in Ayurveda knowledge and healthcare tools to deliver holistic, long-lasting healing for all kind of ailments and diseases of patients."]
        },
        {
            question: "What is panchkarma?",
            answer: ["A novel therapeutical technique derived from age-old knowledge of sages and specialized ayurvedic practitioners. This heals most of life-threatening and life-style diseases without invasive surgeries."]
        },
        {
            question: "What is prakruthi?",
            answer: ["Prakruthi or body type is the constitution based on the combinations of Panchabhuta (air, space, fire, water, and earth). They manifest in the body with varying dominance of three vital energies – principles (dosha) i.e Vata, Pitha, and Kapha."]
        },
        {
            question: "Can a patient use other medications together with Ayurvedic cancer treatments?",
            answer: ["After receiving contemporary scientific treatments such as chemotherapy or radiotherapy, a patient can take Ayurvedic medications with ease and safety. There is no comparison in the activities of contemporary and Ayurvedic medications, hence there are no side effects. It has also been scientifically and experimentally demonstrated that using Ayurvedic medications with contemporary drugs produces much superior outcomes. A healthy patient can take both contemporary and Ayurvedic medications, which can be quite useful to him."]
        },
        {
            question: "Is there an Ayurvedic medication for cancer?",
            answer: ["There is no such sickness as cancer in Ayurveda. Many researchers associate it with ailments listed in Ayurveda such as Arbud and Granthi. We believe that none of these entities can fully encompass today's cancer. As a result, careful consideration is required while naming this disease. Ayurveda has explicitly stated that every sickness does not need to be identified in order to be properly treated; rather, Ayurveda places a greater emphasis on understanding the pathogenesis involved in the condition and initiating therapy to combat the pathology. This is the approach we've taken for many years when arranging therapy for cancer patients, and the outcomes have been quite positive. "]
        },
        {
            question: "Can a patient take additional medications with Ayurvedic cancer treatments?",
            answer: ["After receiving contemporary scientific treatments such as chemotherapy or radiotherapy, a patient can take Ayurvedic medications with ease and safety. There is no comparison in the activities of contemporary and Ayurvedic medications, hence there are no side effects. It has also been scientifically and experimentally demonstrated that using Ayurvedic medications with contemporary drugs produces much superior outcomes. A healthy patient can take both contemporary and Ayurvedic medications, which can be quite useful to him."]
        },
        {
            question: "Can cancer be entirely cured?",
            answer: ["It depends on the disease's stage and nature. There are several forms of cancer that are completely curable. For example. We all know Yuvraj Singh was suffering from seminoma-type cancer. This cancer can be entirely treated. We cannot say that advanced diseases such as brain cancer or ovarian cancer may be completely healed. If a patient seeks Ayurvedic treatment early on, following chemotherapy or radiation, the odds of cure are quite high. Another obstacle in cancer treatment is recurrence or metastatic disease, which kills more than 90% of cancer patients. Ayurvedic medications keep patients healthy following therapy."]
        },
        {
            question: "Are there any food limits for Ayurvedic treatment?",
            answer: ["Most people assume there is a diet plan. In actuality, diabetic patients are frequently advised not to consume sugar, whereas people with high blood pressure are advised to consume less salt. This is also a food regimen, so why is there a question about it in Ayurveda? The primary reason for this regimen is that sugar consumption promotes diabetes and has a harmful influence on the heart and liver, hence these dietary limitations are advised by current research."]
        },{
            question: "Do Ayurvedic medications have any side effects?",
            answer: ["Ayurvedic medications are usually made from vanaspatis and minerals. Very few medications are created from aquatic and animal origins, such as conches and shells. Certain minerals are also employed extensively in Ayurveda. The medications made from these mixes are subsequently mixed and refined, making them completely safe. That is why, the more effectively the drugs are prepared, the better the effects."]
        },
        {
            question: "If I take just Ayurvedic medications, is it okay to not get radiation or chemotherapy?",
            answer: ["When a patient has a primary stage solid tumour, it is frequently recommended that they have surgery. Patients are typically advised to get chemotherapy after surgery. Because they are aware of the negative effects of chemotherapy and radiation, patients' families and relatives frequently express discomfort with these treatments. It's crucial to consider whether Ayurvedic medicine is adequate in this situation. We have carried out these procedures. It was observed that the primary stage did not advance to the secondary stage following the initiation of Ayurvedic treatment. Patients who were informed that their only chance of disease-free survival, median life, or median survival was 12 to 15 months."]
        },
        {
            question: "Is it possible to use Rasayana or Ayurvedic medications while receiving chemotherapy or radiation treatment?",
            answer: ["The answer is a resounding yes. Thousands of patients have taken our medications up to this point while receiving radiation or chemotherapy. Several physicians insist that patients receiving chemotherapy and radiation therapy use Ayurvedic medications. We are pleased to report that oncologists in England recommend Ayurvedic treatment to their patients in addition to chemotherapy or radiation treatments. They are doing this because they have seen the positive reaction. Most patients have experienced very few adverse effects, if any at all. Following their inspection, a number of oncologists from Tata Memorial Hospital in India and the United Kingdom have made this claim. It gives us great pleasure to inform that they suggest Ayurvedic."]
        },

    ]
    return (
        <div>
            {/**OLD FAQ SECTION */}
            <section className="hidden">
                <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
                    <h2 className="mb-6 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
                    <div className="flex py-12 flex-col text-lg text-gray-700 sm:px-8 lg:px-12 xl:px-32">
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">Why Ayurveda for treatment?</summary>
                            <div className="px-4 pb-4">
                                <p>Ayurveda, a repository of holistic medicinal cure, offers natural healing cure for life-style diseases, common ailments and surgeries for complex ailments through Indian medicine procedures that has no further side-effects or complications post-treatment.</p>
                                <p>Ayurveda treatment are the best treatment as they eradicates the disease from the root and also do not have any side effects.</p>
                            </div>
                        </details>
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">What is panchkarma?</summary>
                            <div className="px-4 pb-4">
                                <p>A novel therapeutical technique derived from age-old knowledge of sages and specialized ayurvedic practitioners. This heals most of life-threatening and life-style diseases without invasive surgeries.</p>
                            </div>
                        </details>
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">Why Ayurmax?</summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>Ayurmax is a multispecialty healthcare institution of synchronized Ayurvedic medical procedures and modern technology.  Ayurmax is the best ayurvedic treatments hospital in Dehradun which provide top quality healthcare to its patients and work on the root causes of the diseases.The healthcare professionals at Ayurmax are certified and experienced in Ayurveda knowledge and healthcare tools to deliver holistic, long-lasting healing for all kind of ailments and diseases of patients.</p>
                            </div>
                        </details>
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">Are all 5 Panchakarma necessary?</summary>
                            <div className="px-4 pb-4">
                                <p>Panchakarma is generally performed according to the ailment or illness. The panchakarma is administered as suited for the treatment dosha imbalances present in the body and the prescribed particular panchakarma therapy by the doctor.</p>
                            </div>
                        </details>
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">What is prakruthi?</summary>
                            <div className="px-4 pb-4">
                                <p>Prakruthi or body type is the constitution based on the combinations of Panchabhuta (air, space, fire, water, and earth). They manifest in the body with varying dominance of three vital energies – principles (dosha) i.e Vata, Pitha, and Kapha.</p>
                            </div>
                        </details>
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">Are there any side-effects of ayurvedic medicine?</summary>
                            <div className="px-4 pb-4">
                                <p>Ayurvedic medications are derived from natural herbs and hence are free of any side-effect on the health of the patient. When taken under the prescription of the ayurvedic doctor there are no side-effects. Always visit the hospital if you suffer any adverse reaction after the consumption of non-prescribed medicine.</p>
                            </div>
                        </details>
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">Are surgical methods available in Ayurveda?</summary>
                            <div className="px-4 pb-4">
                                <p>Ayurveda is the origin of the surgery in the world. Shalya sutra and Kshar sutra are the surgical branches for Ayurveda based treatments.</p>
                            </div>
                        </details>
                        <details className='border-b-2 border-b-gray-700'>
                            <summary className="py-2 outline-none cursor-pointer focus:outline-none">Will Ayurvedic treatment eradicate and provide permanent relief?</summary>
                            <div className="px-4 pb-4">
                                <p>Ayurveda works on the basic premise of getting to the root of the ailment to provide relief. Our experienced staff and surgeons provide treatment for most of the illness with long-term relief.</p>
                            </div>
                        </details>
                    </div>
                </div>
            </section>

            <section className="mx-auto max-w-7xl px-2 py-10 md:px-0">
                <div>
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
                            Frequently Asked Questions
                        </h2>
                    </div>
                    <div className="mx-auto mt-8 max-w-3xl space-y-4 md:mt-16">

                        {faqs.map((faq, i) => (
                            <div 
                                key={i}
                                className=" cursor-pointer rounded-md border border-gray-400 transition-all duration-200"
                            >
                                <button
                                    onClick={() => setCurrent(current===null?i:null)}
                                    type="button"
                                    className="flex w-full items-start justify-between px-4 py-5 sm:p-6 md:items-center"
                                >
                                    <span className="flex text-start text-lg font-semibold text-black">
                                        {faq.question}
                                    </span>
                                    {current === i ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i>}

                                </button>
                                {
                                    current === i && (
                                        <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                                            {
                                                faq.answer.map((ans, ansi) => (
                                                    <p className="text-gray-500">
                                                        {ans}
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        ))}
                    </div>
                    <p className="textbase mt-6 text-center text-gray-600">
                        Can&apos;t find what you&apos;re looking for?{' '}
                        <p className="font-semibold text-black">
                            Post Your Question
                        </p>
                        <p className="font-semibold text-black">
                            Fill out the form bellow
                        </p>
                        <p className="font-semibold text-black">
                            <i class="bi bi-arrow-down"></i>
                        </p>

                    </p>
                </div>
            </section>

        </div>
    )
}

export default Quries