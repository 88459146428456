import React from 'react'
import numbersImg from "../assets/images/nubbers-ig.png";

function NumbersCounter() {
  return (
    <div
        className="bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${numbersImg})` }}
      >
        <div className="bg-custom-green/95">
          <div className="max-w-[1000px] mx-auto flex flex-wrap items-center p-2 py-8 ">
            <div className="w-full flex justify-center items-center md:aspect-square md:w-1/2">
              <div className="p-4 text-white text-center md:text-start">
                <p className="text-4xl sm:text-5xl pb-2 heading-c1 font-bold">
                  Numbers We have
                </p>
                <p className="text-xl sm:text-2xl text-c2 pl-2 font-semibold">
                  1134+ patient trust us
                </p>
                <div className="flex justify-center md:justify-start py-8">
                  <a
                    className="inline-flex text-custom-green cursor-pointer items-center gap-1 rounded-full border border-white bg-white px-4 py-2 font-bold ease-in-out duration-300 hover:text-custom-green-light hover:scale-105 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                    href=""
                  >
                    Schedule An Appointment
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center items-center md:aspect-square md:w-1/2">
              <div className="relative p-4">
                <div className="relative inline-grid grid-cols-2 gap-4 grid-rows-2">
                  <span className="w-[2px] absolute h-full left-[50%] bg-white"></span>
                  <span className="w-full absolute h-[2px] top-[50%] bg-white"></span>
                  <div className="p-2">
                    <p className="text-4xl text-center font-bold text-white sm:text-5xl">
                      27+
                    </p>
                    <p className="text-base font-semibold text-white text-center">
                      Year of Exprience
                    </p>
                  </div>
                  <div className="p-2">
                    <p className="text-4xl text-center font-bold text-white sm:text-5xl">
                      10000+
                    </p>
                    <p className="text-base font-semibold text-white text-center">
                      Happy Patients
                    </p>
                  </div>
                  <div className="p-2">
                    <p className="text-4xl text-center font-bold text-white sm:text-5xl">
                      45+
                    </p>
                    <p className="text-base font-semibold text-white text-center">
                      Number of Doctors
                    </p>
                  </div>
                  <div className="p-2">
                    <p className="text-4xl text-center font-bold text-white sm:text-5xl">
                      60+
                    </p>
                    <p className="text-base font-semibold text-white text-center">
                      Number of Staffs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NumbersCounter