import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import person1 from "../assets/images/person1.png";
import person2 from "../assets/images/person2.png";
import person3 from "../assets/images/person3.png";
import person4 from "../assets/images/person4.png";
import person5 from "../assets/images/person5.png";
import person6 from "../assets/images/person6.png";
import person7 from "../assets/images/person7.png";
import profileMale from "../assets/images/profile-male.png";
import profileFemale from "../assets/images/profile-female.png";

function Carousal() {
    const reviews = [
        {
            "name":"Sushil Tyagi",
            "message":"I was diagnosed with Grade-1 stomach cancer that was a tough time for me and my family. But Dr. Arvind Chaudhary and his team gave us new hope. He is treating me for cancer and thankfully I’m recovering well.",
            "profile": person7
        },
        {
            "name":"Ruby Bhandari",
            "message":"I visited Ayurmax hospital with my mom nearly 3-4 months ago as someone suggested us to Dr. Arvind Chaudhary and have been in touch with them since then . The best part there is -whole faculty there is very helpful and specially the reception staff they try their best to help us and are well behaved. The Dr. we are  seeing is best and have been very helpful and my mom is getting better. We also attended sub therapy sessions and the therapist there were also very good at their job . Overall the hospital is best and you just need to trust the doctor and do as he says and you'll get the best results for sure...",
            "profile": person4
        },
        {
            "name":"Dhan Raj",
            "message":"Best hospital in dehradun with excellent doctors. Staff is also very good and helpful. Wonderful experience and fully satisfied with my treatment. A special thanks to Dr. Arvind Chaudhry.",
            "profile": person5
        },
        {
          "name":"Suman Sharma",
          "message":"Dr. Arvind Chaudhary at Ayurmax Hospital provided exceptional care during my recent visit. His expertise and thoroughness in diagnosis were evident, and he took the time to explain the treatment plan in detail. The overall experience was positive, and I highly recommend his services.",
          "profile": person2
      },
      {
          "name":"Srishti",
          "message":"I am grateful for the excellent care I received from Dr. Arvind Chaudhary at Ayurmax Hospital. His approach was not only professional but also compassionate. He listened attentively to my concerns, provided clear explanations, and ensured I felt comfortable throughout the treatment process. Highly satisfied with the service.",
          "profile": person1
      },
      {
          "name":"Vijay Singh",
          "message":"Dr. Arvind Chaudhary at Ayurmax Hospital is truly exceptional. His extensive knowledge and compassionate approach create a reassuring environment for patients. During my visit, he displayed a genuine commitment to understanding my health concerns and provided comprehensive guidance. I highly commend Dr. Chaudhary for his expertise and patient-centered care.",
          "profile": person6
      }
    ]
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                }
              },
            ]
      };
  return (
    <div className="carousal-container">
        <div className="carousal mx-auto">
            <Slider {...settings}>
                {
                    reviews.map((review)=>(
                        <div className="carousal-card shadow-xl rounded-lg overflow-hidden flex flex-col w-[18rem] lg:w-[20rem] h-[25rem] bg-custom-green">
                <div className="img flex justify-center h-[6rem]">
                    <div className='relative border-4 border-custom-green top-[2rem] w-[7rem] h-[7rem] bg-white p-2 overflow-hidden rounded-full flex justify-center items-center'>
                    <img className='w-full h-full rounded-full bg-white' src={review.profile} alt="" />
                    </div>
                </div>
                <div className='text rounded-t-lg bg-white h-[19rem]'>
                    <div className='pt-[3rem]'>
                        <div className='p-2 md:p-6 pt-2'>
                            <p className='text-md md:text-lg font-semibold text-center'>{review.name}</p>
                            <p className='text-md md:text-lg line-clamp-[8] md:line-clamp-6 text-center'>{review.message}</p>
                        </div>
                    </div>
                </div>
            </div>
                    ))
                }
            
            </Slider>
        </div>
    </div>
  )
}

export default Carousal