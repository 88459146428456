import React, { useState } from "react";
import {config} from '../../constant';
import axios from 'axios'
import useAuth from "../../hooks/useAuth";
const URL = config.url;

const ButtonModal = (props) => {
  const {token} = useAuth();
  const [open, setOpen] = useState(false);
  const[loading, setLoading] = useState(false);
  const data = props.data;
  const dataType = props.dataType;
  let queryModal = false;
  if (dataType === "query") {
    queryModal = true;
  }
  function formatDate(date) {
    var newdate = new Date(date);
    var day = newdate.getDate();
    var month = newdate.getMonth() + 1;
    var year = newdate.getFullYear();
    var newdate = day + "-" + month + "-" + year;
    return newdate;
  }
  const deleteAppointmentData = async (id) => {
    //const token = localStorage.getItem('token');
    const url = `${URL}/api/getdata/deleteappointment/?id=${id}&authTocken=${encodeURIComponent(token)}`
    try{
      const responce = await axios.delete(url)
      if(responce.data.sucess){
        setLoading(false)
        alert("Record has been deleted")
        setOpen(!open)
        window.location.reload()
      }
    } catch(error){
      setLoading(false)
      alert("Something went wrong. Please Try later.")
    }
  }
  const deleteQueryData = async (id) => {
    setLoading(true)
    //const token = localStorage.getItem('token');
    const url = `${URL}/api/getdata/deletequery/?id=${id}&authTocken=${encodeURIComponent(token)}`
    try{
      const responce = await axios.delete(url)
      if(responce.data.sucess){
        setLoading(false)
        alert("Record has been deleted")
        setOpen(!open)
        window.location.reload()
      }
    } catch(error){
      setLoading(false)
      alert("Something went wrong. Please Try later.")
    }
  }
  {
    /**deleteappointment deletequery */
  }

  
  return (
    <div>
      <div className="">
        {/* Modal toggle */}
        <button
          onClick={() => setOpen(!open)}
          data-modal-target="authentication-modal"
          data-modal-toggle="authentication-modal"
          className=""
          type="button"
        >
          {props.children}
        </button>
        {/* Main modal */}
        {open && (
          <div
            id="authentication-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed backdrop-blur-sm top-0  flex justify-center items-center  left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-lg max-h-full">
              {/* Modal content */}
              <div className="relative border-2 border-gray-800 bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  onClick={() => setOpen(!open)}
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="authentication-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close Form</span>
                </button>
                <div className="px-10 py-6 lg:px-8">
                  <div className="py-5">
                    {queryModal ? (
                      <div className="text-start">
                        <div className="flex justify-center pb-2">
                          <p className="text-lg px-2 border-b-2 border-b-gray-800 font-semibold text-center py-2">
                            Query
                          </p>
                        </div>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Name : </span>
                          {data.name}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Email : </span>
                          {data.email}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Phone : </span>
                          {data.phone}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Date : </span>
                          {formatDate(data.datetime)}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Message :</span>
                        </p>
                        <p className="py-2 text-base pl-2 font-normal">{data.message}</p>
                        <div className="flex justify-center py-4">
                          {
                          loading?(<div role="status" className="flex justify-center py-2">
                          <div className="flex justify-center items-center">
                          <svg
                            aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-custom-green"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <p className="text-base font-medium pl-4">Please wait...</p>
                          </div>
                          <span class="sr-only">Loading...</span>
                        </div>):(<button onClick={()=>{deleteQueryData(data._id)}} className="px-4 py-2 text-white text-base font-medium mx-2 rounded-xl bg-custom-green hover:bg-custom-green/65">Delete</button>)
                        }                          
                        <a href={`mailto:${data.email}?subject=Responce&body=Your%20Reply%20Content`} className="px-4 py-2 text-white text-base font-medium mx-2 rounded-xl bg-custom-green hover:bg-custom-green/65">Reply on mail</a>
                        </div>
                      </div>
                    ) : (
                      <div className="text-start">
                        <div className="flex justify-center pb-2">
                          <p className="text-lg border-b-2 px-2 border-b-gray-800 font-semibold text-center py-2">
                            Appointment
                          </p>
                        </div>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Name : </span>
                          {data.name}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Email : </span>
                          {data.email}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Phone : </span>
                          {data.phone}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Service Type : </span>
                          {data.serviceType}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">
                            Appointment Date :{" "}
                          </span>
                          {formatDate(data.appointmentDate)}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">
                            Appointment Time :{" "}
                          </span>
                          {data.appointmentTime}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Patient Id : </span>
                          {data.patientId}
                        </p>
                        <p className="py-2 text-base font-normal">
                          <span className="font-medium">Patient Type : </span>
                          {data.type}
                        </p>
                        <div className="flex justify-center mx-2">
                        {
                          loading?(<div role="status" className="flex justify-center py-2">
                          <div className="flex justify-center items-center">
                          <svg
                            aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-custom-green"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <p className="text-base font-medium pl-4">Please wait...</p>
                          </div>
                          <span class="sr-only">Loading...</span>
                        </div>):(<button onClick={()=>{deleteAppointmentData(data._id)}} className="px-4 py-2 text-white text-base font-medium mx-2 rounded-xl bg-custom-green hover:bg-custom-green/65">Delete</button>)
                        }
                          <a href={`mailto:${data.email}?subject=Responce&body=Your%20Reply%20Content`} className="px-4 py-2 text-white text-base font-medium mx-2 rounded-xl bg-custom-green hover:bg-custom-green/65">Reply on mail</a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ButtonModal;
