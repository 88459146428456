import React from 'react'
import malogo1 from "../assets/images/ma-logo-1.jpg";
import malogo2 from "../assets/images/ma-logo-2.jpg";
import malogo3 from "../assets/images/ma-logo-3.png";
import malogo4 from "../assets/images/ma-logo-4.png";

function FeaturedDoctor() {
  return (
    <div className="p-4 py-8 w-full sm:w-3/4 mx-auto">
        <div className="py-4">
          <p className="heading-c1 text-4xl lg:text-6xl font-semibold text-center">
            Featured Doctor On
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 flex-wrap my-8">
          <div className="flex justify-center items-center">
            <div className="w-2/3 m:w-1/2 md:w-[10rem]">
              <img className="w-full" src={'https://res.cloudinary.com/dpzhezt6x/image/upload/v1705516691/nima-logo-800x800_psnx8f.png'} alt="" />
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div className="w-2/3 m:w-1/2 md:w-[10rem]">
              <img className="w-full" src={'https://res.cloudinary.com/dpzhezt6x/image/upload/v1705516685/download_2_i6hsot.jpg'} alt="" />
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div className="w-2/3 m:w-1/2 md:w-[10rem]">
              <img className="w-full" src={'https://res.cloudinary.com/dpzhezt6x/image/upload/v1705516686/logo_1_rz22qr.png'} alt="" />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="w-2/3 m:w-1/2 md:w-[10rem]">
              <img className="w-full" src={'https://res.cloudinary.com/dpzhezt6x/image/upload/v1705516686/download_3_haygpf.jpg'} alt="" />
            </div>
          </div>
        </div>
      </div>
  )
}

export default FeaturedDoctor