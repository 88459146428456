import React from "react";
import { config } from "../../constant";
import { useFetchQuery } from "../../hooks/useFetchQuery";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader";
import useAuth from "../../hooks/useAuth";
const URL = config.url;

function AdminBlog ()  {
  //const token = localStorage.getItem('token');
  const {token} = useAuth();
  const url = `${URL}/api/getdata/getallpost`;
  const [data, loading, error] = useFetchQuery(url);

  const handleDelete = async (id)=>{
    let next = window.confirm("Are you sure. You want to Delete the blog")
    if(!next) return
    const url = `${URL}/api/getdata/deleteblog/?id=${id}&authTocken=${encodeURIComponent(token)}`
    try{
      const responce = await axios.delete(url)
      if(responce.data.sucess){
        alert("Record has been deleted")
        window.location.reload()
      }
    } catch(error){
      alert("Something went wrong. Please Try later.")
    }
  }
  return (
    <div>
      <>
        {loading ? (
          <>
            <Loader/>
          </>
        ) : (
          <>
            <div className=" p-2">
              <div className="py-10 flex justify-between">
                <p className="text-3xl font-semibold text-center py-2 ">
                  Blogs
                </p>
                <a href={'/admin/addblog'}
                  type="button"
                  className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >Add New Post</a>
              </div>
              <div className="max-w-[1200px] mx-auto">
                <section class="text-gray-600 body-font">
                  <div class="px-5 py-24 mx-auto">
                    <div class="flex flex-wrap -m-4">
                      {data.map((post) => (
                        <div className="w-full sm:w-2/3 mx-auto md:w-1/2 lg:w-1/3">
                          <div className="p-4">
                            <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                              <img
                                class="lg:h-48 md:h-36 w-full object-cover object-center"
                                src={post.image}
                                alt="blog"
                              />
                              <div class="p-6">
                                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                                  {/**category if any */}
                                </h2>
                                <h1 class="title-font text-lg line-clamp-2 font-medium text-gray-900 mb-3">
                                  {post.title}
                                </h1>
                                <p class="leading-relaxed mb-3 line-clamp-4">
                                  {post.keywords}
                                </p>
                                <div class="flex items-center flex-wrap ">
                                  <Link
                                    class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                                    to={`/blogview/${post.slug}`}
                                  >
                                    Read More
                                    <svg
                                      class="w-4 h-4 ml-2"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="M5 12h14"></path>
                                      <path d="M12 5l7 7-7 7"></path>
                                    </svg>
                                  </Link>
                                  <span class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                                    <svg
                                      class="w-4 h-4 mr-1"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                      <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                    {post.viewcount}K
                                  </span>
                                  <span class="text-gray-400 inline-flex items-center leading-none text-sm">
                                    <svg
                                      class="w-4 h-4 mr-1"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                    </svg>
                                    {post.commentcount}
                                  </span>
                                </div>
                                <div class="flex items-center justify-between flex-wrap pt-2">
                                  <Link
                                    class="text-[#297922] inline-flex items-center md:mb-2 lg:mb-0"
                                    to={`/admin/blogedit/${post.slug}`}
                                  >
                                    Edit
                                    <i className="bi bi-pencil-square px-2"></i>
                                  </Link>
                                  <button
                                    class="text-red-700 inline-flex items-center md:mb-2 lg:mb-0"
                                    onClick={()=>{handleDelete(post._id)}}
                                  >
                                    Delete <i className="bi bi-trash3-fill pl-2"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default AdminBlog;
