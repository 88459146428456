import React from "react";
import "../assets/style/Common.css";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

function activateMenu() {}
function Navbar() {
  const [open, setOpen] = React.useState(false);
  function menuAction(btn) {
    //const burger  = document.getElementById('burger');
    const menu = document.getElementById("dropdownNavbar");
    menu.classList.toggle("active");
  }
  function itemDrop() {
    const itemmenu = document.getElementsByClassName("dropdown-content");
    itemmenu[0].classList.toggle("activeItem");
    const dropItem = document.getElementsByClassName("dropdown-content-item");
    for (let i = 0; i < dropItem.length; i++) {
      dropItem[i].addEventListener("click", () => {
        itemmenu[0].classList.toggle("activeItem");
      });
    }
  }
  return (
    <>
      <navbar className="">
        <div className="flex flex-col md:flex-row md:justify-between items-center gap-2 lg:px-10 p-3 py-2 bg-custom-green">
          <div className="flex flex-col md:flex-row gap-2 md:gap-5">
            <div className="">
              <a
                className="inline-flex w-full justify-center text-custom-green cursor-pointer items-center gap-1 rounded border border-white bg-white px-4 py-2 font-bold hover:text-custom-green-light focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                href="tel:+918171200004"
              >
                <span className="material-symbols-outlined">phone_in_talk</span>
                +91 8171200004
              </a>
            </div>
            <div className="">
              <Link
                className="inline-flex text-custom-green cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-white bg-white px-4 py-2 font-bold hover:text-custom-green-light focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                to="/appointment"
              >
                Schedule An Appointment
              </Link>
            </div>
          </div>
          <div className="grid grid-flow-col gap-4">
            <a
              className="inline-flex text-2xl text-custom-green cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-white bg-white hover:text-white hover:bg-custom-green px-2 py-1 font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
              href="https://www.facebook.com/arvind.chaudhry.714" target="_blank"
            >
              <i className="bi bi-facebook"></i>
            </a>
            <a
              className="inline-flex text-2xl text-custom-green cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-white bg-white hover:text-white hover:bg-custom-green px-2 py-1 font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
              href="https://www.youtube.com/@ayurmaxhospital9179" target="_blank"
            >
              <i className="bi bi-youtube"></i>
            </a>
            <a
              className="inline-flex text-2xl text-custom-green cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-white bg-white hover:text-white hover:bg-custom-green px-2 py-1 font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
              href="https://www.instagram.com/drarvindchaudhary/" target="_blank"
            >
              <i className="bi bi-instagram"></i>
            </a>
            <a
              className="inline-flex text-2xl text-custom-green cursor-pointer items-center gap-1 rounded border-2 ease-in-out duration-300 border-white bg-white hover:text-white hover:bg-custom-green px-2 py-1 font-semibold focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
              href=""
            >
              <i className="bi bi-twitter"></i>
            </a>
          </div>
        </div>
        <div className="border-b-2 border-b-custom-green p-2 md:p-4 sticky top-0 z-50 w-full bg-white">
          <div className="flex flex-row justify-between items-center">
            <div>
              <Link to="/">
                <img className="h-10 w-auto" src={logo} alt="Workflow" />
              </Link>
            </div>
            <div className="block lg:hidden">
              <label onClick={() => setOpen(!open)} className={` ${open?"activeMenu":"burger"} `} for="burger">
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>
            <div
              id="dropdownNavbar"
              className="absolute bg-gray-300 h-0 left-0 top-full w-full overflow-hidden lg:overflow-visible lg:w-auto lg:bg-transparent lg:relative lg:h-auto"
            >
              <ul className="menuSmall w-full flex flex-col lg:bg-transparent  lg:flex-row">
                {/**
                <li className="dropdown inline-block relative paste-button p-3 mx-2 py-1 ease-in-out text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                  <button className="inline-flex items-center">
                    <span className="mr-1">Treatment</span>
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </button>
                  <ul className="dropdown-menu absolute w-full text-lg hidden mt-1 left-0 pt-1">
                    <li className="">
                      <a
                        className="rounded-t bg-gray-100 hover:bg-gray-400 py-2 px-2 block whitespace-no-wrap"
                        href="/cancercure"
                      >
                        Cancer Cure
                      </a>
                    </li>
                  </ul>
                </li>
                 */}
                 <li className="p-3 mx-2 py-1 ease-in-out text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                  <Link to="/">Home</Link>
                </li>
                <li className="p-3 mx-2 py-1 ease-in-out text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                  <Link to="/cancercure">Cancer Cure</Link>
                </li>
                <li className="p-3 mx-2 py-1 text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green ease-linear duration-200">
                  <Link to="/approach">Holistic Approach</Link>
                </li>
                <li className="p-3 mx-2 py-1 text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green ease-linear duration-200">
                  <Link to="/story">My Story</Link>
                </li>
                <li className="p-3 mx-2 py-1 text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green ease-linear duration-200">
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li className="p-3 mx-2 py-1 text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green ease-linear duration-200">
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className="p-3 mx-2 py-1 text-xl font-semibold border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green ease-linear duration-200">
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div
          className={
            open
              ? "absolute navbar open-navbar overflow-hidden w-full left-0 top-full ease-in-out lg:hidden"
              : "absolute navbar overflow-hidden left-0 w-full top-full transition lg:hidden"
          }
        >
          <div className="h-full shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <ul className="menuSmall w-full flex flex-col lg:bg-transparent  lg:flex-row">
              {/** 
              <li className=" inline-block relative paste-button p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-cutext-custom-green hover:text-custom-green">
                <button onClick={itemDrop} className="inline-flex items-center">
                  <span className="mr-1">Treatment</span>
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </button>
                <ul className="dropdown-menu dropdown-content absolute w-full text-lg hidden mt-1 left-0 pt-1">
                  <li className="dropdown-content-item">
                    <a
                      className="rounded-t bg-gray-100 hover:bg-gray-400 py-2 px-2 block whitespace-no-wrap"
                      href="/cancercure"
                    >
                      Cancer Cure
                    </a>
                  </li>
                </ul>
              </li>*/}
              <li className="p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                <Link to="/" onClick={() => setOpen(!open)}>Home</Link>
              </li>
              <li className="p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                <Link to="/cancercure" onClick={() => setOpen(!open)}>Cancer Cure</Link>
              </li>
              <li className="p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                <Link to="/approach" onClick={() => setOpen(!open)}>Holistic Approach</Link>
              </li>
              <li className="p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                <Link to="/story" onClick={() => setOpen(!open)}>My Story</Link>
              </li>
              <li className="p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                <Link to="/blogs" onClick={() => setOpen(!open)}>Blogs</Link>
              </li>
              <li className="p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                <Link to="/faq" onClick={() => setOpen(!open)}>FAQ</Link>
              </li>
              <li className="p-3 mx-2 py-1 ease-in-out text-2xl border-b-2 border-b-white hover:border-b-custom-green hover:text-custom-green">
                <Link to="/contact" onClick={() => setOpen(!open)}>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
        </div>
      </navbar>
    </>
  );
}

export default Navbar;
