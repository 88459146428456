import React from 'react'
import treatmentImg from "../assets/images/treatmentDiagram.png"

function TreatmentBaner() {
  return (
    <div className="py-8 sm:py-8">
      <div className='bg-[#216222] py-8 sm:py-8'>
      <p className="heading-c1 text-white text-2xl sm:text-3xl lg:text-4xl font-semibold text-center ">
      Effective Ayurveda Treatment of Cancer
          </p>
      </div>
        <div className="mx-auto">
          <img className="mx-auto" src={treatmentImg} alt="" />
        </div>
      </div>
  )
}

export default TreatmentBaner