import React, { useEffect } from "react";
import "../../assets/style/AdminCss.css";
import "../../assets/style/Common.css";
import { Outlet, redirectDocument, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function AdminNav() {
  const navigate = useNavigate();
  const {userLogout, token, isAuthenticated} = useAuth();
  const [open, setOpen] = React.useState(false);
  const logOutHandle = (e)=>{
    //localStorage.removeItem('token')
    //console.log(localStorage.getItem('token'))
    //navigate('/login')
    userLogout();
  }
  useEffect(()=>{
    if(!token){
      navigate('/login')
    }
  },[isAuthenticated])
  return (
    <>
      <div class="relative min-h-screen md:flex" data-dev-hint="container">
        <header
          class="bg-gray-100 text-gray-800 flex justify-between items-center md:hidden"
          data-dev-hint="mobile menu bar"
        >
          <a
            href="/admin/"
            class="block p-4 text-xl text-gray-900 font-bold whitespace-nowrap truncate"
          >
            Dashboard
          </a>
          <div className="px-5">
            <label className="burger" for="burger">
              <input
                onClick={() => setOpen(!open)}
                type="checkbox"
                id="burger"
              />
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>
        </header>
        <aside
          id="sidebar"
          class={
            open
              ? "bg-gray-100 z-50 text-gray-800 md:w-[20rem] w-3/4 pt-6 px-0 h-screen absolute left-0 transform md:relative md:-translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
              : "-translate-x-full h-screen z-50 bg-gray-100 text-gray-800 md:w-64 w-3/4 pt-6 px-0 absolute left-0 transform md:relative md:-translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
          }
        >
          <div
            class="flex flex-col"
            data-dev-hint="optional div for having an extra footer navigation"
          >
            <a
              href="/admin/"
              class="text-gray-800 flex items-center px-4"
              title="Your App is cool"
            >
              <span class="text-xl font-bold whitespace-nowrap truncate">
                Dashboard
              </span>
            </a>
            <nav className="p-4" data-dev-hint="main navigation">
              <ul className="p-4 text-xl font-semibold">
              <li className="px-4 py-2 mb-2 rounded-lg hover:bg-gray-800 hover:text-gray-100">
                  <a className="w-full" href="/admin/allblogs">
                    Blogs
                  </a>
                </li>
                <li className="px-4 py-2 mb-2 rounded-lg hover:bg-gray-800 hover:text-gray-100">
                  <a className="w-full" href="/admin/allappointments">
                    Appointments
                  </a>
                </li>
                <li className="px-4 py-2 mb-2 rounded-lg hover:bg-gray-800 hover:text-gray-100">
                  <a className="w-full" href="/admin/allquries">
                    Queries
                  </a>
                </li>
                <li className="px-4 py-2 mb-2 rounded-lg hover:bg-gray-800 hover:text-gray-100">
                  <a className="w-full" href="/admin/addblog">
                    Add blog
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <nav className="p-4" data-dev-hint="main navigation">
            <ul className="p-4 text-xl font-semibold">
              <li className="px-4 py-2 mb-2 rounded-lg hover:bg-gray-800 hover:text-gray-100">
                <button onClick={logOutHandle} className="w-full" href="">
                  Logout
                </button>
              </li>
            </ul>
          </nav>
        </aside>
        <main id="content" class="h-screen overflow-scroll flex-1 p-6 lg:px-8">
          <div class="max-w-7xl mx-auto p-4">
            <div>
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminNav;
