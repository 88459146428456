import React, { useState } from "react";
import { config } from "../constant";
const URL = config.url;

function Appointment() {
  let initialAppointment = {
    serviceType:"",
    appointmentDate:"",
    appointmentTime:"",
    fullName:"",
    phoneNumber:"",
    emailAddress:"",
    patientId:""
  }
  const [appointment, setAppointment] = useState(initialAppointment);

  const onChange = (e)=>{
    setAppointment({...appointment, [e.target.name]:e.target.value})
    //console.log(appointment)
  }
  const showIdField = (e)=>{
   
    if (e.target.checked){
      document.getElementById("block-patient-id").style.display="block";
      document.getElementById('patientId').removeAttribute('disabled')
    }
    else{
      document.getElementById("block-patient-id").style.display="none";
      document.getElementById('patientId').setAttribute('disabled', true);
    }
  }
  const validator = async (e)=>{
    e.preventDefault();
    const url = `${URL}/api/form/appointment`
    const responce = await fetch(url,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(appointment)
    });
    const json = await responce.json()
    if (json.sucess) {
      alert(json.sucess)
    } else{
      alert("enter a valid value");
    }
  }
  return (
    <div className="relative py-12 flex justify-center items-center backdrop-blur-sm min-h-screen w-screen">
      <div class="relative shadow-2xl flex items-center justify-center p-12 bg-white rounded-3xl">
        <div class="mx-auto w-full max-w-[550px] bg-white">
          <form onSubmit={validator} method="POST">
            <div className="mb-5">
            <p className="text-2xl font-semibold text-center text-[#07074D]">Schedule An Appointment</p>
            </div>
            <div class="mb-5">
              <label
                for="service-type"
                class="mb-3 block text-base font-medium text-[#07074D]"
              >
                Select Service <span className="text-red-700">*</span>
              </label>
              <select
                type="text"
                name="serviceType"
                id="service-ype"
                placeholder="Full Name"
                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required onChange={onChange}
              >
                <option value="video">Virtual Meet</option>
                <option value="audio">Audio Call Only</option>
                <option value="physical">Meetup at office</option>
              </select>
            </div>
            <div class="-mx-3 flex flex-wrap">
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label
                    for="appointmentDate"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Date <span className="text-red-700">*</span>
                  </label>
                  <input
                    type="date"
                    name="appointmentDate"
                    id="appointmentDate"
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    required onChange={onChange}
                  />
                </div>
              </div>
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label
                    for="appointmentTime"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Time <span className="text-red-700">*</span>
                  </label>
                  <input
                    type="time"
                    name="appointmentTime"
                    id="appointmentTime"
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    required
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div class="mb-5">
              <label
                for="appointment-name"
                class="mb-3 block text-base font-medium text-[#07074D]"
              >
                Full Name <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="fullName"
                id="appointment-name"
                placeholder="Full Name"
                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
                onChange={onChange}
              />
            </div>
            <div class="-mx-3 flex flex-wrap">
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label
                    for="appointment-phone"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Phone Number <span className="text-red-700">*</span>
                  </label>
                  <input
                    type="phone"
                    name="phoneNumber"
                    id="appointment-phone"
                    placeholder="Enter your phone number"
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    required
                    onChange={onChange}
                  />
                </div>
              </div>
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label
                    for="appointment-email"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Email Address <span className="text-red-700">*</span>
                  </label>
                  <input
                    type="email"
                    name="emailAddress"
                    id="appointment-email"
                    placeholder="Enter your email"
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    required
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div class="mb-5">
              <div class="flex items-center mb-4">
                <input
                  id="already-patient-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-[#07074D] border border-[#e0e0e0] bg-white rounded focus:ring-[#07074D] dark:focus:ring-[#07074D] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onClick={showIdField}
                />
                <label
                  for="already-patient-checkbox"
                  class="ms-2 text-base font-medium text-[#07074D]"
                >
                  Already patient
                </label>
              </div>
            </div>
            <div id="block-patient-id" class="mb-5 hidden">
              <label
                for="patientId"
                class="mb-3 block text-base font-medium text-[#07074D]"
              >
                Patient id <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="patientId"
                id="patientId"
                placeholder="Enter your Id here"
                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
                onChange={onChange}
                disabled
              />
            </div>

            <div>
              <button class="hover:shadow-form w-full rounded-md bg-custom-green py-3 px-8 text-center text-base font-semibold text-white outline-none">
                Book Appointment
              </button>
            </div>
            <div className="mt-5 hidden justify-center">
              <button
                type="button"
                class=" bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
