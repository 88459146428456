import React from 'react'

function CancerCure() {
  return (
    <div>
      <div className="">
        <section className="relative bg-[url(https://karenthrelkelnd.com/wp-content/uploads/2019/05/naturopathic-medicine-week.jpg)] bg-cover bg-center bg-no-repeat">
          <div className="backdrop-blur-sm h-[15rem] flex items-center justify-center">
            <div className="p-2 py-8 text-black">
              <p className="heading-c1 text-4xl lg:text-6xl font-semibold text-center text-black">Cancer Cure</p>
            </div>
          </div>
        </section>
      </div>
      <div className='max-w-[1200px] mx-auto p-2'>
        <div>
          <div className="blog blog1">
            <div className="sideImg">
              <img src="https://res.cloudinary.com/dpzhezt6x/image/upload/v1705512698/f7a36d3b02247ab818c7b7c3623a4271_tquwq0.jpg" alt="" />
            </div>
            <div className="sideCont">
              <div>
                <h1 className="text-c2 text-4xl font-medium pb-3 lg:-mt-10">
                  Ayurveda's Holistic Approach to Cancer Care
                </h1>
                <p className="leading-loose pb-2 text-base md:text-lg">
                  You're he­re on a website whe­re Ayurveda's powerful he­aling
                  touches the world of cancer tre­atment. At Ayurmax Hospital,
                  we value­ the timeless wisdom of Ayurve­da, touting a
                  broad-view approach to cancer care. This approach is the­
                  harmony of mind, body, and spirit. Our carefully selecte­d
                  Ayurvedic treatments draw from age­-old traditions.
                </p>
                <p className="leading-loose pb-2 text-base md:text-lg">
                  They serve to support the­ usual ways doctors treat your
                  illness. We focus on de­veloping tailor-made wellne­ss plans
                  with intentions to cater to eve­ry person's unique
                  cancer-re­lated needs. Fe­el the strength of nature­'s cure.
                  Mix that with advanced Ayurvedic the­rapies as we launch a
                  journey for ove­rall health and likely relie­f from cancer.
                  Come and discover the­ route where Ayurve­da and modern
                  science me­et, creating complete­ cancer care.
                </p>

              </div>
            </div>
          </div>
        </div>
        <div className='py-10'>
          <div className=''>
            {/** 
            <p className='heading-c1 text-3xl  text-custom-green sm:text-5xl text-center font-semibold'>What Is Naturopathic Medicine?</p>
            <p className='py-10 leading-loose w-full mx-auto lg:w-3/4 text-base sm:text-lg text-gray-700 text-center'>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum dolorum deleniti nobis consectetur, aperiam sit non totam ipsam neque natus, facilis vel modi rem suscipit dicta quam laboriosam magnam itaque laborum autem esse odit, placeat commodi doloribus. Nam quam commodi minus ea veniam, quis expedita eum, similique est odit, ipsa maxime nostrum sit quos! Temporibus porro et officiis magni molestiae alias, perferendis minus velit blanditiis sed aspernatur sunt suscipit cum? Alias, modi sint distinctio error enim cupiditate aperiam natus, ipsa asperiores sed labore architecto, commodi odit voluptate nulla voluptatibus repudiandae consectetur dignissimos ullam! Repellendus, minima. Cum sunt, nulla incidunt doloremque veritatis similique exercitationem totam, tempore reiciendis repellat ullam numquam aliquam iste, nisi fugiat veniam repudiandae illo facilis ipsam! Rem dignissimos velit sequi ut, eos labore corporis doloremque aperiam deleniti repellat perferendis eveniet error amet! Inventore saepe distinctio ratione, eligendi, eos harum culpa id nulla dolorem aliquid velit eaque vero molestias debitis corporis dolorum rerum adipisci nesciunt eius. Placeat deleniti nihil in odit aliquam beatae, maiores provident nulla sapiente perferendis vitae adipisci nostrum tenetur ex odio veritatis sed, laudantium exercitationem culpa necessitatibus officia! Ut minima asperiores molestiae, optio rerum incidunt deleniti neque ipsa hic eius culpa nulla, itaque, facilis aut error?
  </p>*/}
   <p className="py-5 leading-loose w-full mx-auto lg:w-3/4 text-base sm:text-lg text-gray-700 text-center">
              Welcome to a realm of hope and healing—Ayurveda's Holistic
              Approach to Cancer Care at Ayurmax Hospital. In our commitment to
              health and well-being, we champion the synergy between ancient
              wisdom and modern science. Ayurveda, with its time-tested
              principles, takes center stage in our comprehensive approach to
              cancer care. At Ayurmax Hospital, we recognize that each
              individual's journey with cancer is unique. Our holistic approach
              integrates Ayurvedic practices, tapping into the body's innate
              ability to heal itself. We believe in a personalized and
              compassionate strategy, aligning traditional Ayurvedic therapies
              with contemporary medical advancements.
            </p>
            <p className=" py-10 leading-loose w-full mx-auto lg:w-3/4 text-base sm:text-lg text-gray-700 text-center">
              Our team of experienced practitioners crafts individualized
              wellness plans, addressing not just the physical symptoms but also
              the emotional and mental aspects of the cancer journey. From
              herbal remedies to lifestyle adjustments, we empower our patients
              to embrace a holistic lifestyle that complements conventional
              treatments. By fostering harmony between mind, body, and spirit,
              Ayurveda becomes a powerful ally in the fight against cancer. At
              Ayurmax Hospital, we invite you to explore this integrative and
              empowering approach, where the wisdom of Ayurveda converges with
              cutting-edge cancer care. Join us on a transformative journey
              towards comprehensive well-being—a journey that transcends
              conventional boundaries and embraces the holistic tapestry of
              health.
            </p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CancerCure