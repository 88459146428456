import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import axios from "axios";
import { config } from "../../constant";
import { useFetchQuery } from "../../hooks/useFetchQuery";
import useAuth from "../../hooks/useAuth";
const URL = config.url;

function AdminEditBlog () {
  const { slug } = useParams();
  //const token = localStorage.getItem('token');
  const {token} = useAuth();
  const url = `${URL}/api/getdata/getpost/${slug}`;
  const [data, loading, error] = useFetchQuery(url);
  const [postId, setPostId] = useState();

  function createMarkup(data) {
    return { __html: data };
  }

  const editor = useRef(null);
  const [reqloading, setLoading] = useState(false);
  // const [content,setContent] =useState('')

  const [post, setPost] = useState({
    title: "",
    article: "",
    image: "",
    keywords: "",
    viewcount: "",
    commentcount: "",
  });

  useEffect(() => {
    setPost(data);
    setPostId(data._id);
  }, [data]);

  //field changed function
  const fieldChanged = (event) => {
    // console.log(event)
    setPost({ ...post, [event.target.name]: event.target.value });
    //console.log(post);
  };

  const contentFieldChanaged = (data) => {
    setPost({ ...post, article: data });
    //console.log(post);
  };

  {
    /** 
  //handling file chagne event
  const handleFileChange = (event) => {
    //console.log(event.target.files[0]);
    setImage(event.target.files[0]);
  };
*/
  }
  const updatePost = async (e) => {
    //console.log(post);
    setLoading(true);
    e.preventDefault();
    //console.log(post);
    const url = `${URL}/api/form/blogupdate/?authTocken=${encodeURIComponent(token)}`;
    try {
      const responce = await axios.post(
        url,
        {
          id: post._id,
          title: post.title,
          article: post.article,
          viewcount: post.viewcount,
          commentcount: post.commentcount,
          keywords: post.keywords,
          url: post.image,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (responce.data.sucess) {
        setLoading(false);
        alert(responce.data.sucess);
      } else if (responce.data.errors) {
        setLoading(false);
        const message = `${responce.data.errors.map((err) => err.msg)}`;
        alert(message);
      } else {
        setLoading(false);
        alert("enter a valid value");
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
      alert("Something Went wrong. Please try later.");
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div role="status" className="flex justify-center py-2">
            <div className="flex justify-center items-center">
              <svg
                aria-hidden="true"
                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-800"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <p className="text-base font-medium pl-4">Please wait...</p>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </>
      ) : (
        <>
          <div className="p-2 md:px-4">
            <div className="shadow-sm  border-0 mt-2">
              <div>
                {/* {JSON.stringify(post)} */}
                <p className="py-4 text-2xl md:text-4xl font-semibold">
                  Update Post
                </p>
                <form onSubmit={updatePost}>
                  <div className="mb-5">
                    <label
                      for="title"
                      className="block text-gray-500 font-bold pr-4"
                    >
                      Post title
                    </label>
                    <input
                      type="text"
                      id="title"
                      placeholder="Enter here"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      name="title"
                      onChange={fieldChanged}
                      value={post.title}
                      required={true}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      for="title"
                      className="block text-gray-500 font-bold pr-4"
                    >
                      Enter image url here
                    </label>
                    <input
                      type="text"
                      id="title"
                      placeholder="Enter here"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      name="image"
                      onChange={fieldChanged}
                      value={post.image}
                      required={true}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      for="keywords"
                      className="block text-gray-500 font-bold pr-4"
                    >
                      Blog Deacription
                    </label>
                    <input
                      type="text"
                      id="keywords"
                      placeholder="Description"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      name="keywords"
                      onChange={fieldChanged}
                      value={post.keywords}
                    />
                  </div>
                  <div class="flex flex-wrap justify-between">
                    <div class="w-full sm:w-1/3">
                      <div className="mb-5">
                        <label
                          for="viewcount"
                          className="block text-gray-500 font-bold pr-4"
                        >
                          View Counts
                        </label>
                        <input
                          type="number"
                          id="viewcount"
                          placeholder="Enter here"
                          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                          name="viewcount"
                          onChange={fieldChanged}
                          value={post.viewcount}
                        />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/3">
                      <div className="mb-5">
                        <label
                          for="commentcount"
                          className="block text-gray-500 font-bold pr-4"
                        >
                          Comment Count
                        </label>
                        <input
                          type="number"
                          id="commentcount"
                          placeholder="Enter here"
                          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                          name="commentcount"
                          onChange={fieldChanged}
                          value={post.commentcount}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <label
                      for="article"
                      className="block text-gray-500 font-bold pr-4"
                    >
                      Post Content
                    </label>
                    {/* <input
                                type="textarea"
                                id="article"
                                placeholder="Enter here"
                                className="rounded-0"
                                style={{ height: '300px' }}
                            /> */}

                    <JoditEditor
                      ref={editor}
                      value={post.article}
                      onChange={(newContent) =>
                        contentFieldChanaged(newContent)
                      }
                    />
                  </div>

                  {/* file field for upload row immage Check the backend for upload image  */}
                  {/** 
                  <div class="max-w-lg mb-5 hidden">
                    <label
                      class="block mb-2 font-medium text-gray-900 dark:text-white"
                      for="user_avatar"
                    >
                      Upload file
                    </label>
                    <input
                      class="block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="user_avatar_help"
                      id="postImage"
                      name="image"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </div>
                  */}

                  <div className="text-center">
                    {loading ? (
                      <div role="status" className="flex justify-center py-2">
                        <div className="flex justify-center items-center">
                          <svg
                            aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-800"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <p className="text-base font-medium pl-4">
                            Please wait...
                          </p>
                        </div>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="bg-green-700 w-full hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                      >
                        Update Post
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminEditBlog;
