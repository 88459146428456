import React from "react";

function ShowDetails(props) {
  const entries = Object.keys(props.data);
 // console.log(entries)
  return (
    <div
      className={
        props.isOpen
          ? `absolute top-0 left-0 bg-white/35 w-screen h-screen z-50 flex justify-center items-center`
          : "hidden absolute top-0 left-0 bg-white/35 w-screen h-screen z-50 justify-center items-center"
      }
    >
      <div className=" relative w-full overflow-scroll md:w-2/4 rounded-lg border-2 border-green-800 p-4 py-8 md:p-10 bg-white">
        <span
          onClick={props.closeBtn}
          className="absolute top-2 right-2 p-2 text-lg font-bold bg-gray-100 text-green-700"
        >
          <i class="bi bi-x-lg"></i>
        </span>
        <div className="grid grid-cols-1 w-full">
          {
            entries.map(key=>
                <div className="text-lg w-full py-2">
            <p className="font-semibold text-green-700">{key} : <span className="text-black font-medium">{props.data[key]}</span></p>
          </div>
                )
                
          }
          
        </div>
      </div>
    </div>
  );
}

export default ShowDetails;
