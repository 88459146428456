import React from "react";
import { Carousel } from "flowbite-react";
import slider1Desktop from "../assets/images/slider1Desktop.png";
import slider2Desktop from "../assets/images/slider2Desktop.png";
import slider3Desktop from "../assets/images/slider3Desktop.png";
import slider1Mobile from "../assets/images/slider1Mobile.png";
import slider2Mobile from "../assets/images/slider2Mobile.png";
import slider3Mobile from "../assets/images/slider3Mobile.png";

function HomeCarousal() {
  const slides = [
    {
      "desktop":slider1Desktop,
      "mobile":slider1Mobile
    },
    {
      "desktop":slider2Desktop,
      "mobile":slider2Mobile
    },
    {
      "desktop":slider3Desktop,
      "mobile":slider3Mobile
    },
  ]
  return (
    <div className="h-[calc(100vh-216px)] md:h-[calc(100vh-131px)]">
      <Carousel>
        {/** 
        <div className="h-full bg-cover bg-no-repeat bg-center bg-custom-green/95 dark:bg-white dark:text-white">
          <div
            className="bg-no-repeat bg-center bg-cover h-full"
            style={{ backgroundImage: `url(${img1})` }}
          >
            <div className="bg-custom-green/85 h-full">
              <div className="max-w-[1000px] mx-auto flex items-center p-2 h-full">
                <div className="box-border w-full p-2 sm:p-6 md:px-10 bg-opacity-90 ">
                  <p className="text-c1 text-xl sm:text-4xl font-semibold py-1 text-white">
                    Ayurvedic Cancer treatment{" "}
                  </p>
                  <p className="text-c2 text-sm sm:text-lg py-1 text-white line-clamp-4 sm:line-clamp-none">
                    Modern Ayurvedic approaches to cancer treatment often involve a
                    holistic approach of traditional Ayurvedic principles.Ayurveda offer
                    a multi-prong cure for cancer, it can complement conventional
                    treatments to support overall well-being.Ayurvedic practices such as
                    personalized dietary plans, herbal supplements like turmeric,
                    ashwagandha, and tulsi, and stress-reducing techniques like yoga and
                    meditation with standard cancer therapies.
                  </p>

                  <div className="flex justify-start py-1">
                    <a
                      className="inline-flex text-sm sm:text-base text-custom-green cursor-pointer items-center gap-1 rounded-full border-2 border-white bg-white px-4 py-2 font-bold hover:bg-custom-green hover:text-white focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                      href=""
                    >
                      Schedule An Appointment
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
        {
          slides.map((slide) => (
            <div className={`h-full bg-cover bg-center bg-no-repeat bg-white dark:bg-white dark:text-white`}>
              <img src={slide.desktop} className="h-full w-full hidden md:block" alt="slider" />
              <img src={slide.mobile} className="h-full w-full block md:hidden" alt="slider" />
            </div>
          ))
        }
      </Carousel>
    </div>
  );
}

export default HomeCarousal;