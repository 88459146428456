import React from "react";
import heroIg from "../assets/images/hero-img.png";
import personImg from "../assets/images/person.png";
import aboutImg from "../assets/images/about-img.png";
import TreatmentBaner from "../components/TreatmentBaner";
function Story() {
  return (
    <div>
      <div className="w-full relative overflow-hidden">
          <img
            className="absolute bottom-0 bg-center w-full -z-10"
            src={heroIg}
            alt=""
          />
          <div className="flex bg-white/60 h-[10rem] md:h-[15rem] justify-center items-center">
            <p className="p-2 text-2xl sm:text-4xl md:text-6xl text-gray-950 font-semibold font-serif">
            DR. ARVIND KR. CHAUDHARY
            </p>
          </div>
        </div>
      <div className="max-w-[1000px] mx-auto">
        <div>
          <div className="lg:h-[18rem] flex justify-center items-center p-2">
            <div className="shadow-2xl w-full aspect-square sm:h-[22rem] sm:w-[22rem] p-2 border rounded-full lg:rounded-[5rem] overflow-hidden bg-white">
              <div className="border rounded-full lg:rounded-[5rem] overflow-hidden w-full h-full">
                <img
                  className="relative z-10 aspect-square"
                  src={personImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:p-6 border-t-2 border-t-custom-green  py-10">
          <p className="text-2xl sm:text-4xl md:text-6xl py-4 text-center text-gray-800 font-serif">
            About
          </p>
          <p className="text-base sm:text-lg py-4">
            I am Arvind Kr. Chaudhary, the Director and Chief Physician at Ayurmax
            Multispeciality Ayurveda Hospital and Panchkarma Centre. With an
            M.D. in Kaya Chikitsa, awarded with an 'A' Grade from the Rajeev
            Gandhi University of Health Sciences, Bangalore, Karnataka
            (2003-2006), and a B.A.M.S. (1996-2002) from the same esteemed
            institution, my professional journey reflects a commitment to
            excellence in Ayurvedic healthcare. My accomplishments extend to
            extensive diagnostic and clinical skills in managing both Outpatient
            (O.P.D.) and Inpatient (I.P.D.) services, coupled with a wealth of
            experience in training and mentoring on Ayurvedic topics. I take
            pride in developing specialized Ayurveda and Panchkarma treatments
            tailored for diverse health concerns, including Neurological,
            Orthopedic, and Lifestyle disorders. As an active participant in
            academic endeavors, I have been involved in presenting scientific
            papers on emerging Ayurvedic and Modern Medical science.
            Additionally, my engagement in various medical health camps with
            organizations like Jain Samaj, Unesco Club, Jagriti Foundation, and
            Lions Club showcases my commitment to community well-being.
          </p>
        </div>
        <TreatmentBaner/>
        <div className="p-4 md:py-10">
          <div>
            <p className="text-4xl font-serif py-4">My Story</p>
            <div className="w-full h-[4px] bg-custom-green relative flex justify-center items-center">
              <span className="text-4xl font-serif bg-white text-gray-800">
                <i class="bi bi-quote"></i>
              </span>
            </div>
          </div>
          <div className="p-2 md:p-6">
            <p className="text-base md:text-lg py-4 text-gray-800">
              My commitment to continuous learning is evident through my
              fellowship of General Education (F.A.G.E.) from Manipal Academy of
              Higher Education, Karnataka. Furthermore, my career highlights
              include successfully submitting a thesis on 'A Clinical
              Comprehensive Study on the effect of Matra Basti and Vaitaran
              Basti in the management of Gridhrasi w.s.r. to Sciatica,' earning
              recognition at seminars, and receiving awards for contributions to
              Ayurvedic activities. With a focus on advancing Ayurveda, I have
              presented on various topics such as the role of Vaitran Basti in
              managing Amavata (Rheumatoid Arthritis) at the International
              Congress of Ayurveda and discussed the importance of Panchakarma
              Therapies in managing neurological disorders at an international
              seminar on Neurological Disorders.
            </p>
            <p className="text-base md:text-lg py-4 text-gray-800">
              One of my key achievements includes the development of specialized
              Ayurvedic and Panchkarma treatments tailored for a spectrum of
              health concerns, particularly in the realms of Neurological,
              Orthopedic, and Lifestyle disorders. I take pride in my expertise
              in training and mentoring on Ayurvedic topics, sharing knowledge
              and fostering the growth of the Ayurvedic community. Additionally,
              I have presented scientific papers on emerging Ayurvedic and
              Modern Medical science, reflecting my dedication to staying at the
              forefront of advancements in healthcare. I am passionate about
              integrating traditional wisdom with contemporary medical
              practices, and my mission is to contribute significantly to the
              well-being of individuals through the holistic approach of
              Ayurveda
            </p>
          </div>
          <div>
            <div className="w-full h-[4px] bg-custom-green relative flex justify-center items-center">
              <span className="text-4xl font-serif bg-white text-gray-800 rotate-180">
                <i class="bi bi-quote"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="pb-8">
          <div
            className=" relative overflow-hidden bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(${aboutImg})` }}
          >
            <img className="w-full" src={aboutImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Story;
