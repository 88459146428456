import React from 'react'
import { useFetchQuery } from "../hooks/useFetchQuery";
import { config } from "../constant";
const URL = config.url;

function HomeRecentPost(params) {
    const url = `${URL}/api/getdata/getallpost?no=3`;
    const [data, loading, error] = useFetchQuery(url);
    const latestArticle = data;
    return (
        <div className="py-10">
            <div>
                <p className="heading-c1 py-8 text-4xl lg:text-6xl font-semibold text-center">Recent Post</p>
            </div>
            <div className="collection_scrollbar md:flex flex-row flex-nowrap py-12">
                {
                    latestArticle.map((post) => (
                        <div className="w-screen flex justify-center sm:w-auto">
                            <div class="carousal-card shadow-xl rounded-lg overflow-hidden w-[18rem] h-[22rem] sm:w-[20rem] sm:h-[25rem] bg-custom-green">
                                <div class="img w-full flex justify-center h-[10rem] sm:h-[12rem]">
                                    <img className="w-full" src={post.image} alt="" />
                                </div>
                                <div class="text bg-white h-[12rem] sm:h-[13rem]">
                                    <div class="h-full">
                                        <div class="relative h-full flex justify-between flex-col p-6 pt-2">
                                            <div>
                                                <p class="text-lg font-semibold line-clamp-2">{post.title}</p>
                                                <p class="text-base line-clamp-4">
                                                    {post.keywords}
                                                </p>
                                            </div>
                                            <a
                                                className="text-lg font-medium self-baseline border-b-2 border-white hover:border-cutext-custom-green text-custom-green"
                                                href={`/blogview/${post.slug}`}
                                            >
                                                Read more
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default HomeRecentPost