import {React, useEffect, useState} from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import Login from './Login';
import AdminNav from './AdminNav';
function Admin() {
  const navigate = useNavigate()
  const {isAuthenticated, token} = useAuth();
  /**const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(()=>{
    if (!localStorage.getItem('tocken')){
      setIsAuthenticated(true)
    }
  })*/
  /**if(!localStorage.getItem('token')){
    navigate('/login')
  }
  return (
    <AdminNav/>
  )*/
  if(!token){
    navigate('/login')
  }
  if(!isAuthenticated){
    navigate('/login')
  }
  return (
    <AdminNav/>
  )
}

export default Admin