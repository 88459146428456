import "./App.css";
import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Story from "./pages/Story";
import Appointment from "./pages/Appointment";
import Blog from "./pages/Blog";
import Quries from "./pages/Quries";
import CancerCure from "./pages/CancerCure";
import HolisticApproach from "./pages/HolisticApproach";
import BlogView from "./pages/BlogView";
import Root from "./pages/Root";
import Admin from "./components/AdminComponenet/Admin";
import Login from "./components/AdminComponenet/Login";
import AdminHome from "./components/AdminComponenet/AdminHome";
import BlogAdd from "./components/AdminComponenet/BlogAdd";
import Appointments from "./components/AdminComponenet/Appointments";
import AdminQuries from "./components/AdminComponenet/AdminQuries";
import AdminBlog from "./components/AdminComponenet/AdminBlog";
import AdminEditBlog from "./components/AdminComponenet/AdminEditBlog";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    children: [
      {
        path: "",
        element: <Home/>,
      },
      {
        path: "/contact",
        element: <Contact/>,
      },
      {
        path: "/story",
        element: <Story/>,
      },
      {
        path: "/blogs",
        element: <Blog/>,
      },
      {
        path: "/faq",
        element: <Quries/>,
      },
      {
        path: "/appointment",
        element: <Appointment/>
      },
      {
        path: "/cancercure",
        element: <CancerCure/>
      },
      {
        path: "/approach",
        element: <HolisticApproach/>
      },
      {
        path: "/blogview/:slug",
        element: <BlogView/>
      }
    ],
  },
  {
    path: "/admin/",
    element: <Admin/>,
    children: [{
      path: "",
      element: <AdminHome/>
    },
    {
      path: "addblog",
      element: <BlogAdd/>
    },{
      path: "allappointments",
      element:<Appointments/>
    },
    {
      path: "allquries",
      element:<AdminQuries/>
    },
    {
      path:"allblogs",
      element:<AdminBlog/>
    },
    {
      path:"blogedit/:slug",
      element:<AdminEditBlog/>
    },
  ]
  },
  {
    path: "/login",
    element: <Login/>
  }
]);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
