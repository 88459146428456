import React, { useEffect } from "react";
import ButtonModal from "./ButtonModal";
import { config } from "../../constant";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useFetchQuery } from "../../hooks/useFetchQuery";
import ShowDetails from "./ShowDetails";
import useAuth from "../../hooks/useAuth";
const URL = config.url;

function AdminHome() {

  //const token = localStorage.getItem('token');
  const {token} = useAuth();
  const url = `${URL}/api/getdata/getappointments/?no=5&authTocken=${encodeURIComponent(token)}`
  const [appointments, loading, error] = useFetchQuery(url)
  const url2 = `${URL}/api/getdata/getquery/?no=5&authTocken=${encodeURIComponent(token)}`
  const [queries, loading2, error2] = useFetchQuery(url2)
  function formatDate(date) {
    var newdate = new Date(date);
    var day = newdate.getDate();
    var month = newdate.getMonth() + 1;
    var year = newdate.getFullYear()
    var newdate = day + "-" + month + "-" + year;
    return newdate
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);

  function openModal(e) {
    const dataid = e.target.getAttribute('dataid');
    if(e.target.getAttribute('dataType')==='appointment'){
      setModalData(appointments.find((appointments)=> appointments._id === dataid))
    }else{
      setModalData(queries.find((queries)=> queries._id === dataid))
    }
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      zIndex: 50
    }}
  return (
    <div className="p-2 sm:p-4">
        <ShowDetails isOpen={modalIsOpen} closeBtn={closeModal} data={modalData}/>
      <div className="">
        <div className="py-2 flex justify-between border-b-2 border-custom-green-light ">
          <p className="text-xl font-bold text-custom-green-light ">
            Appointments
          </p>
          <a className="p-2 mx-2 rounded-lg bg-green-600 font-bold text-white" href="/admin/allappointments">View All</a>
        </div>
        <div className="p-2">
          <div class="relative overflow-x-auto">
            <table class="w-full text-sm text-left rtl:text-right">
              <thead class="text-lg font-sans uppercase border-b-2 border-b-green">
                <tr>
                  <th class="px-6 py-3">
                    Name
                  </th>
                  <th class="px-6 py-3">
                    Email
                  </th>
                  <th class="px-6 py-3 text-center ">
                    Date
                  </th>
                  <th class="px-6 py-3 text-center ">
                  </th>
                </tr>
              </thead>
              <tbody className="text-base font-medium">
                {appointments.map((appointment) => (
                  <tr class="bg-white border-b dark:bg-green-400 dark:border-custom-green-light  hover:bg-green-200">
                    <td class="px-6 py-2">{appointment.name}</td>
                    <td class="px-6 py-2">{appointment.email}</td>
                    <td class="px-6 py-2 text-center">{formatDate(appointment.appointmentDate)}</td>
                    <td className="px-6 py-2 text-center">
                    <ButtonModal className="text-custom-green  hover:text-custom-green-light " data={appointment} dataType="appointment">
                        view
                      </ButtonModal>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="py-12">
        <div className="py-2 flex justify-between border-b-2 border-custom-green-light ">
          <p className="text-xl font-bold text-custom-green-light ">
            Queries
          </p>
          <a className="p-2 mx-2 rounded-lg bg-green-600 font-bold text-white" href="/admin/allquries">View All</a>
        </div>
        <div className="p-2">
          <div class="relative overflow-x-auto">
            <table class="w-full text-sm text-left rtl:text-right">
              <thead class="text-lg font-sans uppercase border-b-2 border-b-green">
                <tr>
                  <th class="px-6 py-3">
                    Name
                  </th>
                  <th class="px-6 py-3">
                    Email
                  </th>
                  <th class="px-6 py-3 text-center ">
                    Date
                  </th>
                  <th class="px-6 py-3 text-center ">
                  </th>
                </tr>
              </thead>
              <tbody className="text-base font-medium">
                {
                  queries.map((query) => (
                    <tr class="bg-white border-b dark:bg-green-400 dark:border-custom-green-light  hover:bg-green-200">
                      <td class="px-6 py-2">{query.name}</td>
                      <td class="px-6 py-2">{query.email}</td>
                      <td class="px-6 py-2 text-center">{formatDate(query.datetime)}</td>
                      <td className="px-6 py-2 text-center">
                      <ButtonModal className="text-custom-green  hover:text-custom-green-light " data={query} dataType="query">
                        view
                      </ButtonModal>
                      </td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
