import React from "react";
import Carousal from "../components/Carousal";
import FeaturedDoctor from "../components/FeaturedDoctor";
import personImg from "../assets/images/person.png";
import HomeRecentPost from "../components/HomeRecentPost";
import HomeCarousal from "../components/HomeCarousal";
import NumbersCounter from "../components/NumbersCounter";
import TreatmentBaner from "../components/TreatmentBaner";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      {/* <Helmet>
        <meta charSet="utf-8" />

        <title>
          Cancer Specialist || DR. ARVIND KR. CHAUDHARY | Ayurmax Hospital In
          Dehradun
        </title>
        <meta
          name="description"
          content="Ayurveda's powerful he­aling touches the world of cancer tre­atment. At Ayurmax Hospital, we value­ the timeless wisdom of Ayurve­da, touting a broad-view approach to cancer care."
        />
        <meta
          name="keywords"
          content="cancer specialist in dehradun, best cancer doctor in india, cancer doctor, cancer specialist, ayurmax hospital ,ayurveda doctor in dehradun, ayurveda doctor, ayurveda doctor in india, best ayurveda doctor, cancer treatment , cancer cause ,ayurveda hospital in dehradun, ayurveda treatment in dehradun, panchkarma treatment in dehradun "
        />

        <meta
          name="google-site-verification"
          content="AEu3fKiHUYP1fs6YXscOCmsOODNIKUwTRf_T5gkKYBc"
        />
        <meta
          property="og:title"
          content=" Cancer Specialist || DR. ARVIND KR. CHAUDHARY | Ayurmax Hospital In
          Dehradun"
        />
        <meta
          property="og:description"
          content="Ayurveda's powerful he­aling touches the world of cancer tre­atment. At Ayurmax Hospital, we value­ the timeless wisdom of Ayurve­da, touting a broad-view approach to cancer care."
        />
        <meta
          property="og:image"
          content="https://www.ayurcan.in/static/media/person.1185719636e8eeeefd7b.png"
        />
        <meta property="og:url" content="https://www.ayurcan.in/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content="  Cancer Specialist || DR. ARVIND KR. CHAUDHARY | Ayurmax Hospital In
          Dehradun"
        />
      </Helmet> */}
      {/** 
      <div className="hidden {grid} grid-cols-1 lg:grid-cols-2 relative overflow-hidden p-2 sm:p-6 lg:p-10 font-sans">
      <img
      src={img1}
      className="absolute top-0 left-0 w-full h-full sm:h-auto -z-10"
      alt=""
      />
      <div className="box-border w-full p-2 sm:p-6 md:px-10 py-12 bg-opacity-90 bg-custom-green-light">
      <p className="text-c1 text-2xl sm:text-4xl font-semibold py-2 text-white">
      Ayurvedic Cancer treatment{" "}
      </p>
      <p className="text-c2 text-base sm:text-xl py-2 text-white">
      Modern Ayurvedic approaches to cancer treatment often involve a
      holistic approach of traditional Ayurvedic principles.Ayurveda offer
      a multi-prong cure for cancer, it can complement conventional
            treatments to support overall well-being.Ayurvedic practices such as
            personalized dietary plans, herbal supplements like turmeric,
            ashwagandha, and tulsi, and stress-reducing techniques like yoga and
            meditation with standard cancer therapies.
            </p>
            
            <div className="flex justify-center py-4">
            <a
              className="inline-flex text-custom-green cursor-pointer items-center gap-1 rounded-full border-2 border-white bg-white px-4 py-2 font-bold hover:bg-custom-green hover:text-white focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
              href=""
              >
              Schedule An Appointment
              </a>
              </div>
              </div>
              </div>
              */}
      <HomeCarousal />
      <FeaturedDoctor />
      <TreatmentBaner />
      <NumbersCounter />
      <div className="p-2 sm:p-4 sm:py-10">
        <div className="grid grid-cols-1 md:grid-cols-5 py-4">
          <div className="md:col-span-3 px-4">
            <div className="text-gray-800">
              {/** <p className="text-c2 py-4 text-2xl font-medium">
                Looking For A Holistic And Naturopathic Approach In Washington
                DC?
                </p>*/}

              <div className="md:hidden min-w-[14rem] pt-4 aspect-square sm:w-1/2 overflow-hidden mx-auto">
                <img src={personImg} alt="" />
              </div>
              <p className="heading-c1 py-4 text-4xl font-semibold">
                Meet Dr. Arvind Chaudhary
              </p>
              <p className="py-4 text-lg ">
                I am Arvind Chaudhary, a seasoned professional in the field of
                Ayurveda and the proud Director and Chief Physician at Ayurmax
                Multispeciality Ayurveda Hospital and Panchkarma Centre. With
                extensive diagnostic and clinical skills, I have successfully
                managed both Outpatient (O.P.D.) and Inpatient (I.P.D.)
                services, demonstrating a commitment to providing comprehensive
                healthcare solutions.
              </p>
              <div className="pt-8">
                <a
                  className="inline-flex text-white cursor-pointer items-center gap-1 rounded border-2 border-custom-green bg-custom-green px-4 py-2 font-bold hover:text-custom-green hover:bg-white focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-300 focus-visible:ring-offset-2 active:opacity-100"
                  href="/story"
                >
                  Know More About Dr. Someone
                </a>
              </div>
            </div>
          </div>
          <div className="hidden md:col-start-4 col-span-2 md:flex justify-center items-center">
            <div className="w-full lg:w-2/3">
              <img src={personImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 py-8 md:p-4 lg:p-8">
        <div className="heading-c1 py-8 text-4xl lg:text-6xl font-semibold text-center">
          <p>Read trusted reviews</p>
          <p>from our customers</p>
        </div>
        <div className="py-8">
          <Carousal />
        </div>
      </div>
      <div className="px-2 py-8 bg-red-100">
        <div className="py-10">
          <p className="heading-c1 text-4xl lg:text-6xl font-semibold text-center text-custom-green">
            Medical Awards & Associations
          </p>
        </div>
        <div className="flex gap-4 flex-wrap flex-row py-8 justify-center items-center">
          <div className="w-full md:w-1/4 lg:w-1/6 flex justify-center items-center">
            <div className="w-[16rem] md:w-[18rem]">
              <img
                className="h-fit mx-auto"
                src="https://karenthrelkelnd.com/wp-content/uploads/2023/12/americas-best-dr-2020.png"
                alt=""
              />
            </div>
          </div>
          <div className="w-full md:w-1/4 lg:w-1/6 gap-y-[2rem] flex justify-center items-center">
            <div className="w-[16rem] md:w-[18rem]">
              <img
                className="h-fit mx-auto"
                src="https://karenthrelkelnd.com/wp-content/uploads/2023/04/2023.png"
                alt=""
              />
            </div>
          </div>
          <div className="w-full md:w-1/4 lg:w-1/6 flex justify-center items-center">
            <div className="w-[16rem] md:w-[18rem]">
              <img
                className="h-fit mx-auto"
                src="https://karenthrelkelnd.com/wp-content/uploads/2023/12/dr-threlkel-top-doctor-2023-e1703257858107.png"
                alt=""
              />
            </div>
          </div>
          <div className="w-full md:w-1/4 lg:w-1/6 flex justify-center items-center">
            <div className="w-[16rem] md:w-[18rem]">
              <img
                className="h-fit mx-auto"
                src="https://karenthrelkelnd.com/wp-content/uploads/2019/11/top-doctor-badge-150x150.png"
                alt=""
              />
            </div>
          </div>
          <div className="w-full md:w-1/4 lg:w-1/6 flex justify-center items-center">
            <div className="w-[16rem] md:w-[18rem]">
              <img
                className="h-fit mx-auto"
                src="https://karenthrelkelnd.com/wp-content/uploads/2023/12/americas-best-dr-2020.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <HomeRecentPost />
    </div>
  );
}

export default Home;
